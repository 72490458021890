import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../shared/services/config.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import moment from 'moment';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';

@Component({
  selector: 'app-loan-residential-address',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-residential-address.component.html',
  styleUrl: './loan-residential-address.component.scss',
})
export class LoanResidentialAddressComponent implements OnInit {
  form!: FormGroup;
  areaDataSource: any[] = [];

  constructor(
    private store: Store,
    private translate: TranslateService,
    private configService: ConfigService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.areaDataSource = this.configService.getConfig().area;
    this.initializeForm();
  }

  // Form
  get unitFormControl() {
    return this.form.get('unit')!;
  }

  get floorFormControl() {
    return this.form.get('floor')!;
  }

  get blockFormControl() {
    return this.form.get('block')!;
  }

  get buildingFormControl() {
    return this.form.get('building')!;
  }

  get districtFormControl() {
    return this.form.get('district')!;
  }

  get areaFormControl() {
    return this.form.get('area')!;
  }

  initializeForm() {
    this.form = new FormGroup({
      unit: new FormControl('', []),
      floor: new FormControl('', []),
      block: new FormControl('', []),
      building: new FormControl('', [Validators.required, Validators.pattern(/^((?!\p{Script=Han}).)*$/u)]),
      district: new FormControl('', [Validators.required, Validators.pattern(/^((?!\p{Script=Han}).)*$/u)]),
      area: new FormControl(this.areaDataSource[0].code, [Validators.required]),
    });
  }

  continueClick(): void {
    
    this.store.dispatch(
      LoanApplicationActions.setHomeAddress({
        unit: this.unitFormControl.value,
        floor: this.floorFormControl.value,
        block: this.blockFormControl.value,
        building: this.buildingFormControl.value,
        district: this.districtFormControl.value,
        area: this.areaFormControl.value,
      })
    );

    this.router.navigate(['/loan-advanced-info']);

  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }
}
