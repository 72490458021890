import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  ApiService,
  FacialRecognitionError,
  FileDescription,
  PassiveLivenessError,
  PassiveLivenessOs,
} from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { catchError, first, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Subject, Observable } from 'rxjs';
import {
  WebcamImage,
  WebcamInitError,
  WebcamModule,
  WebcamUtil,
} from 'ngx-webcam';
import { ConfigService } from '../../../shared/services/config.service';
import { ImageService } from '../../../shared/services/image.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-loan-selfie-instruction',
  standalone: true,
  imports: [CommonModule, SharedModule, WebcamModule],
  templateUrl: './loan-selfie-instruction.component.html',
  styleUrl: './loan-selfie-instruction.component.scss',
})
export class LoanSelfieInstructionComponent implements OnInit {
  screenWidth: number;
  screenHeight: number;

  public width: number;
  public height: number;
  // public width: number = 300;
  // public height: number = 300;

  // toggle webcam on/off
  public showWebcam = false;
  public allowCameraSwitch = false;
  public multipleWebcamsAvailable = false;
  public deviceId!: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage | undefined;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  flippedImage!: string;
  error: string = '';
  isProduction: boolean = environment.production;

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    console.log('resize window.innerWidth: ', win.innerWidth);
    console.log('resize window.innerHeight: ', win.innerHeight);
    this.width = win.innerWidth;
    this.height = win.innerHeight;
  }

  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private configService: ConfigService,
    private imageService: ImageService,
    private errorHandlingService: ErrorHandlingService    
  ) {
    // console.log('window.screen.width: ', window.screen.width);
    // console.log('window.screen.height: ', window.screen.height);

    this.screenWidth = window.screen.width;
    this.screenHeight = window.screen.height;
    const win = !!event ? (event.target as Window) : window;
    console.log('constructor window.innerWidth: ', win.innerWidth);
    console.log('constructor window.innerHeight: ', win.innerHeight);

    this.width = win.innerWidth;
    this.height = win.innerHeight;
  }

  ngOnInit() {}
  open(content: TemplateRef<any>) {
    this.modalService.open(content);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.flip(webcamImage.imageAsDataUrl);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  start() {
    // this.store.dispatch(
    //   LoanApplicationActions.setVersion({
    //     version: version,
    //   })
    // );
    // clear error message
    this.error = '';

    this.modalService.dismissAll();
    this.toggleWebcam();
    // this.router.navigate(['/loan-hkid-capture']);
  }

  reset() {
    this.webcamImage = undefined;
  }

  resetToInstruction(error: string) {
    this.reset();
    this.toggleWebcam();
    this.error = error;
  }

  flip(src: any) {
    const img = new Image();
    img.onload = () => {
      var c = document.createElement('canvas');
      c.width = img.width;
      c.height = img.height;
      var ctx = c.getContext('2d');
      ctx!.scale(-1, 1);
      ctx!.drawImage(img, -img.width, 0);
      img.onload = null;
      const flippedImage = c.toDataURL();
      this.flippedImage = flippedImage;
    };
    img.src = src;
  }

  // continue() {
  //   this.spinnerService.showSpinner();
  //   // retrieve the application id from store from upcoming operation
  //   this.store
  //     .select(LoanApplicationFeature.selectInfo)
  //     .pipe(
  //       first(),
  //       switchMap(({ applicationId, version }) =>
  //         this.uploadFileAndPassiveLiveness(applicationId, this.flippedImage)
  //       ),
  //       switchMap((selfieFileId) => this.facialRecognition(selfieFileId))
  //     )
  //     .subscribe({
  //       next: (result) => {
  //         // result
  //         console.log(`result: ${result}`);
  //         console.log(result);
  //       },
  //       error: (e) => {
  //         console.info('error');
  //         console.log(e.message);

  //         if (e instanceof PassiveLivenessError) {
  //           console.log('catch error PassiveLivenessError');
  //           this.resetToInstruction(e.message);
  //         } else {
  //           window.alert(e.message);
  //         }

  //         this.spinnerService.hideSpinner();
  //       },
  //       complete: () => {
  //         console.info('complete');
  //         this.spinnerService.hideSpinner();
  //       },
  //     });
  // }

  continue() {
    this.spinnerService.showSpinner();
    // retrieve the application id from store from upcoming operation

    this.uploadFileAndPassiveLiveness(this.flippedImage)
      .pipe(switchMap((selfieFileId) => this.facialRecognition(selfieFileId)))
      .subscribe({
        next: () => {
          // result
          // console.log(`result: ${result}`);
          // console.log(result);

          this.router.navigate(['/loan-residential-address']);
          this.resetToInstruction('');
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();

          if (
            e instanceof PassiveLivenessError ||
            e instanceof FacialRecognitionError
          ) {
            console.log(`catch error ${e}`);
            this.resetToInstruction(e.message);
          } else {
            this.errorHandlingService.handleError(e);
          }

        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
        },
      });
  }

  uploadFileAndPassiveLiveness(file: string): Observable<number> {
    // add count for liveness attempt
    console.log('update Passive Liveness Attempt');
    this.store.dispatch(LoanApplicationActions.updatePassiveLivenessAttempt());


   const extension = this.imageService.getFileExtensionFromDataURL(file);

    return this.store.select(LoanApplicationFeature.selectInfo).pipe(
      first(),
      switchMap(({ applicationId }) =>
        this.apiService
          .createFileId(
            applicationId,
            FileDescription.Selfie,
            'selfie.' + extension,
            'image of the Selfie'
          )
          .pipe(
            switchMap((fileId) =>
              this.apiService
                .uploadFile(fileId, file)
                .pipe(map((result) => fileId))
            ),
            switchMap((fileId) => {
              let platform = this.detectPlatform();
              return this.apiService
                .passiveLiveness(applicationId, fileId, platform)
                .pipe(
                  catchError((error) => this.handlePassiveLivenessError(error)),
                  map(() => fileId)
                );
            })
          )
      )
    );
  }

  // uploadFileAndPassiveLiveness(
  //   applicationId: number,
  //   file: string
  // ): Observable<number> {
  //   // add count for liveness attempt
  //   console.log('update Passive Liveness Attempt');
  //   this.store.dispatch(LoanApplicationActions.updatePassiveLivenessAttempt());

  //   return this.apiService
  //     .createFileId(
  //       applicationId,
  //       FileDescription.Selfie,
  //       'selfie.jpg',
  //       'Jpg of the Selfie'
  //     )
  //     .pipe(
  //       switchMap((fileId) =>
  //         this.apiService.uploadFile(fileId, file).pipe(map((result) => fileId))
  //       ),
  //       switchMap((fileId) => {
  //         let platform = this.detectPlatform();
  //         return this.apiService
  //           .passiveLiveness(applicationId, fileId, platform)
  //           .pipe(
  //             catchError((error) => this.handlePassiveLivenessError(error)),
  //             map(() => fileId)
  //           );
  //       })
  //     );
  // }

  handlePassiveLivenessError(error: Error) {
    console.log('handle Passive Liveness Error');
    return this.store
      .select(LoanApplicationFeature.selectPassiveLivenessAttempt)
      .pipe(
        first(),
        mergeMap((attempt) => {
          let maxAttempt =
            this.configService.getConfig().passive_liveness_attempt;
          console.log(`current attempt: ${attempt}`);
          console.log(`max attempt: ${maxAttempt}`);
          if (attempt >= maxAttempt) {
            return of(true);
          } else {
            throw error;
          }
        })
      );
  }

  facialRecognition(selfieFileId: number) {
    console.log('update Facial Recognition Attempt');
    this.store.dispatch(
      LoanApplicationActions.updateFacialRecognitionAttempt()
    );
    return this.store
      .select(LoanApplicationFeature.selectInfoForFacialRecognition)
      .pipe(
        first(),
        switchMap(({ applicationId, headFileId }) =>
          this.apiService
            .facialRecognition(applicationId, headFileId, selfieFileId)
            .pipe(
              tap(() => {
                // This will only execute if the observable emits successfully
                // set selfie file Id
                console.log('set selfie file id to store');
                this.store.dispatch(
                  LoanApplicationActions.setSelfieFileId({
                    selfieFileId: selfieFileId,
                  })
                );
              }),
              catchError((error) => this.handleFacialRecognitionError(error))
            )
        )
      );
  }

  handleFacialRecognitionError(error: Error) {
    console.log('handle Facial Recognition Error');
    return this.store
      .select(LoanApplicationFeature.selectFacialRecognitionAttempt)
      .pipe(
        first(),
        mergeMap((attempt) => {
          let maxAttempt =
            this.configService.getConfig().facial_recognition_attempt;
          console.log(`current attempt: ${attempt}`);
          console.log(`max attempt: ${maxAttempt}`);
          if (attempt >= maxAttempt) {
            return of(true);
          } else {
            throw error;
          }
        })
      );
  }

  detectPlatform(): PassiveLivenessOs {
    const userAgent = navigator.userAgent;
    let w = window as any;
    if (/iPad|iPhone|iPod/.test(userAgent) && !w.MSStream) {
      return PassiveLivenessOs.Ios;
    } else if (/Android/.test(userAgent)) {
      return PassiveLivenessOs.Android;
    } else if (/Windows|Macintosh/.test(userAgent)) {
      return PassiveLivenessOs.Desktop;
    } else {
      return PassiveLivenessOs.Unknown;
    }
  }

  // for testing purpose only
  onFileSelected(event: any) {
    const reader = new FileReader();
    reader.onload = () => {
      this.flippedImage = reader.result as string;
      console.log(this.flippedImage);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  submit() {
    this.continue();
  }
}
