import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../shared/services/config.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import moment from 'moment';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';

@Component({
  selector: 'app-loan-personal-detail-demographic',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-personal-detail-demographic.component.html',
  styleUrl: './loan-personal-detail-demographic.component.scss',
})
export class LoanPersonalDetailDemographicComponent implements OnInit {
  form!: FormGroup;
  residentialDataSource: any[] = [];
  maritalDataSource: any[] = [];
  educationDataSource: any[] = [];

  constructor(
    private store: Store,
    private translate: TranslateService,
    private configService: ConfigService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.residentialDataSource =
      this.configService.getConfig().residential_status;
    this.maritalDataSource = this.configService.getConfig().marital_status;
    this.educationDataSource = this.configService.getConfig().education_level;
    console.log(this.residentialDataSource);
    this.initializeForm();
  }

  // Form
  get residentialFormControl() {
    return this.form.get('residential')!;
  }

  get maritalFormControl() {
    return this.form.get('marital')!;
  }

  get educationFormControl() {
    return this.form.get('education')!;
  }

  get monthMartgageFormControl() {
    return this.form.get('martgage')!;
  }

  get monthRentalFormControl() {
    return this.form.get('rental')!;
  }

  initializeForm() {
    this.form = new FormGroup({
      residential: new FormControl('', [Validators.required]),
      marital: new FormControl('', [Validators.required]),
      education: new FormControl('', [Validators.required]),
      martgage: new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999999)]),
      rental: new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999999)]),
    });
  }

  continueClick(): void {
    this.store.dispatch(
      LoanApplicationActions.setAdvancedInfo({
        residentialStatus: this.residentialFormControl.value,
        maritalStatus: this.maritalFormControl.value,
        educationLevel: this.educationFormControl.value,
        monthlyMartgageAmount: this.monthMartgageFormControl.value,
        monthlyRentalAmount: this.monthRentalFormControl.value,
      })
    );

    this.router.navigate(['/loan-employment-detail']);
  }

  backClick() {
    this.location.back();
  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }
}
