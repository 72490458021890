import { Injectable } from '@angular/core';
import { AprRow } from '../interfaces/apr-row';

@Injectable({
  providedIn: 'root',
})
export class CalculatorService {
  constructor() {}

  /**
   *
   * @param loanAmount
   * @param tenorInMonths
   * @param apr in percentage
   * @returns Monthly Installment
   */
  calculateMonthlyInstallment(
    loanAmount: number,
    tenorInMonths: number,
    apr: number
  ): number {

    const monthlyInterestRate = apr / 100 / 12;
    const monthlyInstallment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -tenorInMonths));

    return Math.ceil(monthlyInstallment);
  }
}
