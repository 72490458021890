<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_RESIDENTIAL_ADDRESS.HOME_ADDRESS" | translate}}</h6>
    <div class="diff-bg-container">
        <p class="text-center w-75 centered mb-3">
            {{"LOAN_RESIDENTIAL_ADDRESS.INSTRUCTION" | translate}}
        </p>
        <form [formGroup]="form">

            <div class="inline-div mb-2">
                <div class="form-group input-unit">
                    <label for="unit" class="text-gray">&emsp;{{"LOAN_RESIDENTIAL_ADDRESS.UNIT" | translate}}</label><br>
                    <input type="text" class="form-control form-input-borders" formControlName="unit">
                    <div *ngIf="unitFormControl.invalid && (unitFormControl.dirty || unitFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="unitFormControl.errors?.['required']">{{"LOAN_RESIDENTIAL_ADDRESS.REQUIRE_UNIT" | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group input-floor">
                    <label for="floor" class="text-gray">&emsp;{{"LOAN_RESIDENTIAL_ADDRESS.FLOOR" | translate}}</label><br>
                    <input type="text" class="form-control form-input-borders" formControlName="floor">
                    <div *ngIf="floorFormControl.invalid && (floorFormControl.dirty || floorFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="floorFormControl.errors?.['required']">{{"LOAN_RESIDENTIAL_ADDRESS.REQUIRE_FLOOR" | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="block" class="text-gray">&emsp;{{"LOAN_RESIDENTIAL_ADDRESS.BLOCK" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="block">
                <div *ngIf="blockFormControl.invalid && (blockFormControl.dirty || blockFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="blockFormControl.errors?.['required']">{{"LOAN_RESIDENTIAL_ADDRESS.REQUIRE_BLOCK" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="building" class="text-gray">&emsp;{{"LOAN_RESIDENTIAL_ADDRESS.BUILDING_NAME" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="building" required>
                <div *ngIf="buildingFormControl.invalid && (buildingFormControl.dirty || buildingFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="buildingFormControl.errors?.['required']">{{"LOAN_RESIDENTIAL_ADDRESS.REQUIRE_BUILDING" | translate}}
                    </div>
                    <div *ngIf="buildingFormControl.errors?.['pattern']">{{"LOAN_RESIDENTIAL_ADDRESS.ENTER_ENGLISH" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="district" class="text-gray">&emsp;{{"LOAN_RESIDENTIAL_ADDRESS.DISTRICT" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="district" required>
                <div *ngIf="districtFormControl.invalid && (districtFormControl.dirty || districtFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="districtFormControl.errors?.['required']">{{"LOAN_RESIDENTIAL_ADDRESS.REQUIRE_DISTRICT" | translate}}
                    </div>
                    <div *ngIf="districtFormControl.errors?.['pattern']">{{"LOAN_RESIDENTIAL_ADDRESS.ENTER_ENGLISH" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="area" class="text-gray">&emsp;{{"LOAN_RESIDENTIAL_ADDRESS.AREA" | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="area" required>
                    <option *ngFor="let el of areaDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}</option>
                </select>
                <div *ngIf="areaFormControl.invalid && (areaFormControl.dirty || areaFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="areaFormControl.errors?.['required']">{{"LOAN_RESIDENTIAL_ADDRESS.REQUIRE_AREA" | translate}}
                    </div>
                </div>
            </div>

        </form>
    </div>
    

    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!form.valid"
            (click)="continueClick()">{{"LOAN_RESIDENTIAL_ADDRESS.CONTINUE" | translate}}</button>
    </div>


</div>