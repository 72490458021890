import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const checkPageRefreshGuard: CanActivateFn = (route, state) => {
  // I determine if the current route-request is part of a page refresh.
  function isPageRefresh() {
    // If the router has yet to establish a single navigation, it means that this
    // navigation is the first attempt to reconcile the application state with the
    // URL state. Meaning, this is a page refresh.
    return !inject(Router).navigated;
  }

  if (isPageRefresh()) {
    return inject(Router).parseUrl('/');
  }
  return true;
};
