<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_MOBILE.ENTER" | translate}}</h6>
    <div class="diff-bg-container">
        <div class="text-center">
            <img alt="" class="img-fluid width-35" src="assets/img/icon_2_mobile.png">
        </div>


        <p class="with-margin text-center">{{"LOAN_MOBILE.PLEASEENTER" | translate}}</p>

        <div class="mb-3">

            <form [formGroup]="mobileForm">

                <div class="form-group w-75 centered">
                    <input type="tel" class="form-control  bg-color text-center" [placeholder]="'LOAN_MOBILE.MOBILE' | translate" formControlName="mobile" required>
                    <div *ngIf="mobileFormControl.invalid && (mobileFormControl.dirty || mobileFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="mobileFormControl.errors?.['required']">{{"LOAN_MOBILE.MOBILE_REQUIRED" | translate}}
                        </div>
                        <div *ngIf="mobileFormControl.errors?.['pattern']">{{"LOAN_MOBILE.MOBILE_INVALID" | translate}}
                        </div>
                    </div>
                </div>

            </form>
        </div>
        
        <hr class="hr-decoration">

        <div class="mb-3">
            <div class="w-75 centered mb-2 text-center">
                <span class="title-bold">{{"LOAN_MOBILE.PREPARE" | translate}}</span>
                <span class="size-10px">{{"LOAN_MOBILE.REQUIRED" | translate}}</span>
                <span>{{"LOAN_MOBILE.:" | translate}}</span>
            </div>
            <div class="text-center text-blue size-11px">
                1.{{"LOAN_MOBILE.HKID" | translate}} <br>
                2.{{"LOAN_MOBILE.INCOME" | translate}}<br>
                3.{{"LOAN_MOBILE.HOLDER" | translate}}
            </div>
        </div>
    </div>
    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!mobileForm.valid"
            (click)="continueClick()">{{"LOAN_MOBILE.CONTINUE" | translate}}</button>
        <button class="btn-white" (click)="backClick()">{{"LOAN_MOBILE.BACK" | translate}}</button>
    </div>

</div>