/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { APP_VERSION } from './version';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if(!environment.enableConsole){
  console.log = function():void{}; 
  console.debug = function():void{};
  console.warn = function():void{};
  console.info = function():void{};
  enableProdMode();
}

console.log(`%cApp version: ${APP_VERSION}`, 'color: blue');

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
