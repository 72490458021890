import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ApiService,
  FileDescription,
} from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../shared/services/config.service';
import { Observable, first, map, switchMap } from 'rxjs';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { SharedModule } from '../../../shared/shared.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-loan-document-selection',
  standalone: true,
  imports: [CommonModule, RouterLink, SharedModule, FormsModule],
  templateUrl: './loan-document-selection.component.html',
  styleUrl: './loan-document-selection.component.scss',
})
export class LoanDocumentSelectionComponent implements OnInit {
  allowSubmit: boolean = false;
  documents: {
    fileId: number;
    fileType: FileDescription;
  }[] = [];

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.store
      .select(LoanApplicationFeature.selectDocuments)
      .pipe(first())
      .subscribe({
        next: (documents) => {
          console.log(documents);
          this.documents = documents;
          this.allowSubmit = documents.length > 0;
        },
        error: (e) => {
          console.info('error');
          console.log(e.message);
          window.alert(e.message);
        },
        complete: () => {
          console.info('complete');
        },
      });
  }

  get isIncome1Uploaded() {
    return this.documents.some(
      (item) => item.fileType === FileDescription.Income1
    );
  }

  get isIncome2Uploaded() {
    return this.documents.some(
      (item) => item.fileType === FileDescription.Income2
    );
  }

  get isIncome3Uploaded() {
    return this.documents.some(
      (item) => item.fileType === FileDescription.Income3
    );
  }

  get isAddressUploaded() {
    return this.documents.some(
      (item) => item.fileType === FileDescription.Address
    );
  }

  get isBankUploaded() {
    return this.documents.some(
      (item) => item.fileType === FileDescription.Bank
    );
  }

  get isOtherUploaded() {
    return this.documents.some(
      (item) => item.fileType === FileDescription.Other
    );
  }

  submit() {
    this.router.navigate(['/loan-thank-you']);
  }
}
