import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { LoanIndexComponent } from './features/loan/loan-index/loan-index.component';
import { UploadIndexComponent } from './features/upload/upload-index/upload-index.component';
import { FooterComponent } from './core/layout/footer/footer.component';
import { HeaderComponent } from './core/layout/header/header.component';
import { NoCameraDeviceComponent } from './features/error/no-camera-device/no-camera-device.component';
import { NonMobileDeviceComponent } from './features/error/non-mobile-device/non-mobile-device.component';
import { SharedModule } from './shared/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { LoanCalculatorComponent } from './features/loan/loan-calculator/loan-calculator.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ConfigService } from './shared/services/config.service';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { SpinnerService } from './shared/services/spinner.service';
import { SpinnerComponent } from './shared/components/spinner.component';
import VConsole from 'vconsole';
import { ProgressBarComponent } from './core/layout/progressbar/progressbar.component';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    CommonModule,
    SharedModule,
    FooterComponent,
    HeaderComponent,
    NoCameraDeviceComponent,
    NonMobileDeviceComponent,
    LoanIndexComponent,
    LoanCalculatorComponent,
    SpinnerComponent,
    ProgressBarComponent,
  ],
})
export class AppComponent implements OnInit {
  title = 'tuweb';
  isMobileDevice: boolean = false;
  isCameraAvailable: boolean = false;
  showSpinner = false;
  enableVConsole: boolean = environment.enableVConsole;

  clickCount: number = 0;
  showVconsole: boolean = false;
  vConsole: any = null;

  constructor(
    translate: TranslateService,
    public router: Router,
    private gtmService: GoogleTagManagerService,
    private configService: ConfigService,
    private spinnerService: SpinnerService
  ) {
    console.log(environment.apiUrl);

    this.spinnerService.spinner$.subscribe((data: boolean) => {
      setTimeout(() => {
        this.showSpinner = data ? data : false;
      });
      console.log('this.showSpinner', this.showSpinner);
    });

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.configService.getConfig().default_locale);
    translate.use(localStorage.getItem('locale') || '');

    // set up gtm event on pageview
    router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        console.log('push tag on url: ', item.url);
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.enableVConsole) {
      this.vConsole = new VConsole();
      this.vConsole.setOption('log.maxLogNumber', 1000);
      console.log('vConsole started');
      this.vConsole.hideSwitch();
    }

    this.loadScript('/assets/js/iovation.config.js');

    this.loadScript(
      'https://deweb.transunion.hk/DCPortal/Static/js/iovation.loader.min.js'
    );
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  handleFooterClick() {
    if (this.enableVConsole) {
      this.clickCount++;
      console.log('clickCount: ' + this.clickCount);
      if (this.clickCount === 10) {
        this.vConsole.showSwitch();
      }
    }
  }
}
