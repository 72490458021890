import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, delay, map } from 'rxjs/operators';
import { Post } from '../interfaces/post';
import { environment } from '../../../environments/environment';
import { NewApplication } from '../interfaces/new-application';
import { SendOtp } from '../interfaces/send-otp';
import { ValidateOtp } from '../interfaces/validate-otp';
import { CreateFileId } from '../interfaces/create-file-id';
import { ImageQualityAssessment } from '../interfaces/image-quality-assessment';
import { DocAuthLite } from '../interfaces/doc-auth-lite';
import { DocAuthOcr } from '../interfaces/doc-auth-ocr';
import { PersonalDetail } from '../interfaces/personal-detail';
import { VelocityCheck } from '../interfaces/velocity-check';
import { PassiveLiveness } from '../interfaces/passive-liveness';
import { FacialRecognition } from '../interfaces/facial-recognition';
import { MicroFlowDOWeb } from '../interfaces/micro-flow-do-web';
import { SearchApplication } from '../interfaces/search-application';
import { ImageService } from './image.service';

export class GenericError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class VelocityError extends Error {
  constructor(message: string = 'Velocity Error') {
    super(message);
  }
}

export class MaxResendOTPError extends Error {
  constructor(message: string = 'Max Resend OTP Error') {
    super(message);
  }
}

export class MaxVerifyOTPError extends Error {
  constructor(message: string = 'Max Verify OTP Error') {
    super(message);
  }
}

export class DocAuthLiteError extends Error {
  croppedImageId: number;
  constructor(message: string = 'Doc Auth Lite Error', croppedImageId: number) {
    super(message);
    this.croppedImageId = croppedImageId;
  }
}

export class ImageQualityAssessmentError extends Error {
  constructor(message: string = 'Image Quality Assessment Error') {
    super(message);
  }
}

export class DocAuthOcrError extends Error {
  constructor(message: string = 'Doc Auth Ocr Error') {
    super(message);
  }
}

export class PassiveLivenessError extends Error {
  constructor(message: string = 'Passive Liveness Error') {
    super(message);
  }
}

export class FacialRecognitionError extends Error {
  constructor(message: string = 'Facial Recognition Error') {
    super(message);
  }
}

export class ApplicationNotFoundError extends Error {
  constructor(message: string = 'Application Not Found Error') {
    super(message);
  }
}

export enum FileDescription {
  IdVideo = 'IDVideo',
  IdDocument = 'IDDocument',
  IdHead = 'IDHead',
  Selfie = 'Selfie',
  Income1 = '1stMonthIncomeStatement',
  Income2 = '2ndMonthIncomeStatement',
  Income3 = '3rdMonthincomeStatement',
  Address = 'AddressProof',
  Bank = 'BankAccountHolderProof',
  Other = 'OtherDocument',
}

export enum PassiveLivenessOs {
  Ios = 'IOS',
  Android = 'ANDROID',
  Desktop = 'DESKTOP',
  Unknown = 'UNKNOWN',
}

export function mapResponseBody<T>(): (
  source: Observable<HttpResponse<any>>
) => Observable<any> {
  return (source: Observable<HttpResponse<any>>) => {
    return source.pipe(map((response: HttpResponse<any>) => response.body));
  };
}

export function mapResponseHeaderAndBody<T>(): (
  source: Observable<HttpResponse<any>>
) => Observable<{ headers: any; body: any }> {
  return (source: Observable<HttpResponse<any>>) => {
    return source.pipe(
      map((response: HttpResponse<any>) => {
        const modifiedResponse = {
          headers: response.headers,
          body: response.body,
        };
        return modifiedResponse;
      })
    );
  };
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // Define API
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // Default Headers
  defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json; charset=utf-8',
  });

  customRequest(path: string, params: any, headers?: HttpHeaders) {
    return this.http
      .post<string>(this.apiURL + path, params, {
        observe: 'response', // response would include the header
        headers: headers || this.defaultHeaders,
      })
      .pipe(
        // todo: network error handling
        catchError((error: any) => {
          // non 200 request error handling
          console.log('apiservice: error: ', error);

          let message = error.error.error_description
            ? error.error.error_description // token api
            : error.error.Message; // other api

          throw new GenericError(message);
        }),
        map((response: any) => {
          console.log('response: ', response);

          //Generic Error Handling
          if (response.body.Status === 'Failed') {
            throw new Error(response.body.ErrorInfo);
          }

          if (response.body.errorId) {
            throw new Error(response.body.ErrorMessage);
          }

          return response;
        })
      );
  }

  getToken(): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json; charset=utf-8',
    });

    return this.customRequest(`/TU.DE.Pont/token`, {}, headers).pipe(
      map((response: any) => response.body.access_token)
    );
  }

  newApplication(): Observable<NewApplication> {
    return this.customRequest(`/TU.DE.Pont/applications`, {
      RequestInfo: {
        SolutionSetId: 505,
        ExecuteLatestVersion: true,
        ExecutionMode: 'NewWithContext',
      },
      Fields: {
        ServiceType: 'NewApplication',
        Applicants_IO: {
          Applicant: [
            {
              Consent: 'Y',
              InfoDeclaration: 'Y',
              TermsConditions: 'Y',
              PrivacyPolicy: 'Y',
              OTPResult: 'Pass',
            },
          ],
        },
        ExternalApplicationId: 'xxx',
      },
    }).pipe(mapResponseBody());
  }

  sendOTP(applicationId: number, mobile: string): Observable<SendOtp> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_SendOTP`,
      {
        Fields: {
          ServiceType: 'OTP',
          ServiceStep: 'SendOTP',
          Applicants_IO: {
            Applicant: [
              {
                Telephones: {
                  Telephone: [
                    {
                      TelephoneCountryCode: '852',
                      TelephoneNumber: mobile,
                      TelephoneType: 'P',
                      MFormat: 'S',
                    },
                  ],
                },
              },
            ],
          },
          ApplicationData_IO: {
            MessageType: 'P',
            Attributes: {
              SelectedTemplate: '1',
            },
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: SendOtp) => {
        console.log('response: ', response);
        if (
          !response.Fields.Applicants_IO.Applicant[0].DSOTPSendOTPStatus
            .IsSuccess
        ) {
          let errorCode =
            response.Fields.Applicants_IO.Applicant[0].DSOTPSendOTPStatus
              .ErrorCode;

          switch (errorCode) {
            case '101':
              throw new MaxResendOTPError(
                response.Fields.Applicants_IO.Applicant[0].DSOTPSendOTPStatus.ErrorMessage
              );

              break;
            default:
              throw new Error(
                response.Fields.Applicants_IO.Applicant[0].DSOTPSendOTPStatus.ErrorMessage
              );
              break;
          }
        }
        return response;
      })
    );
  }

  // "ErrorMessage": "Maximum OTP Verify Attempt Count Reached!",
  // "ErrorCode": "101"
  validateOTP(applicationId: number, otp: string): Observable<ValidateOtp> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_ValidateOTP`,
      {
        Fields: {
          ServiceType: 'OTP',
          ServiceStep: 'ValidateOTP',
          Applicants_IO: {
            Applicant: [
              {
                UserOTPCode: otp,
              },
            ],
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: ValidateOtp) => {
        if (
          !response.Fields.Applicants_IO.Applicant[0].DSOTPValidateOTPStatus
            .IsSuccess
        ) {
          if (
            response.Fields.Applicants_IO.Applicant[0].DSOTPValidateOTPStatus
              .ErrorCode === '101'
          ) {
            throw new MaxVerifyOTPError(
              response.Fields.Applicants_IO.Applicant[0].DSOTPValidateOTPStatus.ErrorMessage
            );
          } else {
            throw new Error(
              response.Fields.Applicants_IO.Applicant[0].DSOTPValidateOTPStatus.ErrorMessage
            );
          }
        }
        return response;
      })
    );
  }

  createFileId(
    applicationId: number,
    description: FileDescription,
    fileName: string,
    note: string
  ): Observable<number> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/documents#CreateFileId`,
      {
        Description: description,
        FileName: fileName,
        Note: note,
      }
    ).pipe(
      mapResponseHeaderAndBody(),
      map(({ headers, body }) => {
        console.log(`headers:`, headers);
        const location = headers.get('Location');
        let fileId = parseInt(location.replace('/documents/', ''));
        console.log('File Id: ', fileId);
        return fileId;
      })
    );
  }

  /**
   *
   * @param docId from createFileId
   * @param file file binary string
   * @returns
   */
  uploadFile(docId: number, file: string): Observable<any> {
    const headers = new HttpHeaders({
      // 'Content-Type': 'image/jpg',
      'Content-Type': 'text/plain',
      Accept: 'application/json; charset=utf-8',
    });

    var im = new ImageService();
    var fileBlob = im.dataURLtoBlob(file);

    return this.customRequest(
      `/TU.DE.Pont/Documents/${docId}`,
      // file,
      fileBlob,
      headers
    ).pipe(
      mapResponseBody(),
      map((response: any) => {
        // status code 200 mean upload success
        return response;
      })
    );
  }

  imageQualityAssessment(
    applicationId: number,
    fileId: number
  ): Observable<boolean> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_daliteimagequalityassessment`,
      {
        Fields: {
          ServiceType: 'DALITE_IMAGEQUALITYASSESSMENT',
          Applicants_IO: {
            Applicant: [
              {
                Document: {
                  ImageType: 'raw',
                  Image: {
                    Type: 'FileId',
                    Value: fileId,
                  },
                },
              },
            ],
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: ImageQualityAssessment) => {
        console.log(response);

        let applicant = response.Fields.Applicants_IO.Applicant[0];

        let result =
          applicant.DALite_ImageQualityAssessmentStatus.IsSuccess &&
          applicant.Attributes.DSDALite_ImageQualityAssessment.QualityResult ===
            'Pass';

        if (!result) {
          throw new ImageQualityAssessmentError();
        }

        console.log('pass image resut');

        return result;
      })
    );
  }

  docAuthLite(
    applicationId: number,
    fileIds: Array<number>,
    version: number
  ): Observable<number> {
    // ): Observable<Boolean> {
    // ): Observable<DocAuthLite> {
    if (fileIds.length < 3) {
      throw new Error('FileIds less then 3');
    }
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_dalitedocauth`,
      {
        Fields: {
          ServiceType: 'DALITE_DOCAUTH',
          Applicants_IO: {
            Applicant: [
              {
                Document: {
                  VerticalFront: {
                    Type: 'FileId',
                    Value: fileIds[0],
                  },
                  InclinedFront: {
                    Type: 'FileId',
                    Value: fileIds[1],
                  },
                  VerticalBack: {
                    Type: 'FileId',
                    Value: fileIds[2],
                  },
                  Version: version,
                },
              },
            ],
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: DocAuthLite) => {
        console.log('DocAuthLite response');

        let applicant = response.Fields.Applicants_IO.Applicant[0];

        let success =
          applicant.DALite_DocAuthStatus.IsSuccess &&
          applicant.Attributes.DSDALite_DocAuth.FinalResult === 'Pass';

        let croppedImageId =
          applicant.Attributes.DSDALite_DocAuth.CroppedImageID;

        if (!success) {
          throw new DocAuthLiteError('DocAuthLiteError', croppedImageId);
        }

        return croppedImageId;
      })
    );
  }

  docAuthOcr(
    applicationId: number,
    fileId: number,
    version: number
  ): Observable<DocAuthOcr> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_docauth-ocr`,
      {
        Fields: {
          ServiceType: 'DSDocAuthOCR',
          Applicants_IO: {
            Applicant: [
              {
                Document: {
                  Front: {
                    FileId: fileId,
                  },
                  Version: version,
                },
              },
            ],
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: DocAuthOcr) => {
        console.log(`DocAuthOcr response`);

        // if (
        //   response.Fields.Applicants_IO.Applicant[0].Attributes.DSDocAuthOCR
        //     .OCRValidation !== 'Pass' ||
        //   !response.Fields.Applicants_IO.Applicant[0].DSDocAuthOCRStatus
        //     .IsSuccess
        // ) {
        //   throw new DocAuthOcrError();
        // }

        if (
          !response.Fields.Applicants_IO.Applicant[0].DSDocAuthOCRStatus
            .IsSuccess
        ) {
          throw new DocAuthOcrError();
        }

        return response;
        // return response.Fields.Applicants_IO.Applicant[0].Attributes
        //   .DSDocAuthOCR;
      })
    );
  }

  velocityCheck(
    applicationId: number,
    hkid: string,
    applicantLastName: string,
    applicantFirstName: string,
    product: number,
    applicationRequestDate: string
  ): Observable<boolean> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_velocityCheck`,
      {
        Fields: {
          ServiceType: 'VELOCITYCHECK',
          Applicants_IO: {
            Applicant: {
              ApplicantLastName: applicantLastName,
              ApplicantFirstName: applicantFirstName,
              Identifiers: {
                Identifier: [
                  {
                    IdType: 'ID',
                    IdIssuingCountry: 'HKG',
                    IdNumber: hkid,
                  },
                ],
              },
            },
          },
          ApplicationData_IO: {
            Product: product,
            ApplicationRequestDate: applicationRequestDate,
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: VelocityCheck) => {
        console.log(`VelocityCheck response`);

        let result =
          response.Fields.Applicants_IO.Applicant[0].Attributes.VelocityCheck
            .Outcome === 'Pass' &&
          response.Fields.Applicants_IO.Applicant[0].VelocityCheckStatus
            .IsSuccess;

        if (!result) {
          throw new VelocityError();
        }

        return result;
      })
    );
  }

  passiveLiveness(
    applicationId: number,
    fileId: number,
    os: PassiveLivenessOs
  ): Observable<boolean> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_LiveFaceID/`,
      // `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_livefaceid`,
      {
        Fields: {
          ServiceType: 'LiveFaceID',
          Applicants_IO: {
            Applicant: [
              {
                Selfie: {
                  Type: 'ID',
                  Value: fileId,
                  OS: os,
                },
              },
            ],
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: PassiveLiveness) => {
        console.log(`PassiveLiveness response`);
        let applicant = response.Fields.Applicants_IO.Applicant[0];

        let result =
          applicant.DSLiveFaceIDStatus.Outcome === 'Success' &&
          applicant.DSLiveFaceIDStatus.IsSuccess === 'True';

        if (!result) {
          throw new PassiveLivenessError();
        }

        return result;
      })
    );
  }

  facialRecognition(
    applicationId: number,
    headFileId: number,
    selfieFileId: number
  ): Observable<boolean> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_FR/`,
      {
        Fields: {
          ServiceType: 'DSFR',
          Applicants_IO: {
            Applicant: [
              {
                Document: {
                  Head: {
                    FileId: headFileId,
                  },
                  Selfie: {
                    FileId: selfieFileId,
                  },
                },
              },
            ],
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: FacialRecognition) => {
        console.log(`FacialRecognition response`);
        let applicant = response.Fields.Applicants_IO.Applicant[0];

        let result = applicant.DSFRStatus.IsSuccess;

        if (!result) {
          throw new FacialRecognitionError();
        }

        return result;
      })
    );
  }

  microFlowDOWeb(data: {
    applicationId: number;
    applicantFirstName: string;
    applicantLastName: string;
    dateOfBirth: string;
    title: string;
    idNumber: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    telephoneCountryCode: string;
    telephoneNumber: string;
    emailAddress: string;
    monthlyMortgageAmount: string;
    monthlyRentalPayment: string;
    employmentStatus: string;
    occupationCode: string;
    companyName: string;
    businessNatureCode: string;
    lengthOfEmploymentInMonths: string;
    yearsInCurrentProfession: string;
    monthlyIncome: string;
    otherMonthlyIncome: string;
    accountCode: string;
    blackbox: string;
    statedIp: string;
    product: number;
    loanAmount: number;
    tenorInMonths: string;
    language: string; // ZH or ??
    applicationRequestDate: string; // 01011990
    clientThirdPartyConsent: boolean; // Y or N
    directMarketing: boolean; // Y or N
    eStatement: boolean; // Y or N
    maritalStatus: string;
    educationLevel: string;
    residentialStatus: string;
  }): Observable<boolean> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${data.applicationId}/queues/TSO_ServiceInput_MicroFlowDOWeb/`,
      {
        Fields: {
          ServiceType: 'MicroFlowDOWeb',
          Applicants_IO: {
            Applicant: [
              {
                ApplicantFirstName: data.applicantFirstName,
                ApplicantMiddleName: '',
                ApplicantLastName: data.applicantLastName,
                ApplicantType: 'Primary',
                DOBSymbols: '',
                Title: data.title,
                DateOfBirth: data.dateOfBirth,
                MaritalStatus: data.maritalStatus,
                Qualification: data.educationLevel,
                HomeOwnership: data.residentialStatus,
                Identifiers: {
                  Identifier: [
                    {
                      IdType: 'ID',
                      IdNumber: data.idNumber,
                      IdIssuingCountry: 'HKG',
                    },
                  ],
                },
                Addresses: {
                  Address: [
                    {
                      AddressType: 'R',
                      AddressLine1: data.addressLine1,
                      AddressLine2: data.addressLine2,
                      AddressLine3: data.addressLine3,
                      AddressLine4: data.addressLine4,
                    },
                  ],
                },
                Telephones: {
                  Telephone: [
                    {
                      TelephoneCategory: '',
                      TelephoneCountryCode: data.telephoneCountryCode,
                      TelephoneNumber: data.telephoneNumber,
                      TelephoneType: 'P',
                      TelephoneAreaCode: '',
                      MFormat: 'P',
                    },
                  ],
                },
                EmailAddresses: {
                  EmailAddress: [
                    {
                      Value: data.emailAddress,
                      Type: 'P',
                      Category: 'P',
                    },
                  ],
                },
                FinInfo: {
                  MonthlyMortgageAmount: data.monthlyMortgageAmount,
                  MonthlyRentalPayment: data.monthlyRentalPayment,
                },
                EmpInfo: {
                  EmploymentStatus: data.employmentStatus,
                  OccupationCode: data.occupationCode,
                  CompanyName: data.companyName,
                  BusinessNatureCode: data.businessNatureCode,
                  LengthOfEmploymentInMonths: data.lengthOfEmploymentInMonths,
                  YearsInCurrentProfession: data.yearsInCurrentProfession,
                  MonthlyIncome: data.monthlyIncome,
                  OtherMonthlyIncome: data.otherMonthlyIncome,
                  SalaryPaidMethod: '',
                  MPFStatus: '',
                },
                DeviceRequest: {
                  accountCode: data.accountCode,
                  blackbox: data.blackbox,
                  statedIp: data.statedIp,
                  transactionInsight: '',
                  type: 'application',
                },
              },
            ],
          },
          ApplicationData_IO: {
            Product: data.product,
            // PostLoanAmount: data.postLoanAmount,
            LoanAmount: data.loanAmount,
            CurrencyCode: 'HKD',
            // PostAPR: data.postAPR,
            // PostTenor: data.postTenor,
            TenorInMonths: data.tenorInMonths,
            Language: data.language,
            ApplicationRequestDate: data.applicationRequestDate,
            ClientThirdPartyConsent: data.clientThirdPartyConsent ? 'Y' : 'N',
            DirectMarketing: data.directMarketing ? 'Y' : 'N',
            eStatement: data.eStatement ? 'Y' : 'N',
          },
        },
      }
    ).pipe(
      mapResponseBody(),
      map((response: MicroFlowDOWeb) => {
        let applicant = response.Fields.Applicants_IO.Applicant[0];

        // let result =
        //   applicant.MicroFlowDOWebStatus.IsSuccess === true &&
        //   applicant.MicroFlowDOWebStatus.Outcome === 'Pass';

        // if (!result) {
        //   throw new Error(
        //     `Error Component: ${applicant.MicroFlowDOWebStatus.ErrorComponent}, Message: ${applicant.MicroFlowDOWebStatus.ErrorMessage}`
        //   );
        // }

        return true;
      })
    );
  }

  searchApplication(
    applicationId: number,
    mobile: string
  ): Observable<SearchApplication> {
    return this.customRequest(
      `/TU.DE.Pont/applications/${applicationId}/queues/TSO_ServiceInput_searchapplication`,
      {
        PageNo: '1',
        PageSize: '1',
        UTCOffSet: '-300',
        IndexFields: [
          {
            Name: 'ApplicationId',
            OperatorType: '=',
            Value: applicationId,
          },
        ],
        ExtendedSearchFields: [
          {
            Name: 'Cust06', // phone number
            operatorType: '=',
            FieldType: 'string',
            Value: mobile,
          },
        ],
      }
    ).pipe(
      mapResponseBody(),
      map((response: SearchApplication) => {
        console.log(`SearchApplication response`);

        // three cases here
        // 1. pass
        // 2. record not found
        // 3. error, message field would exist
        if (response.Message) {
          throw new Error(response.Message);
        }

        if (
          !response.ResponseInfo.TotalRecords ||
          response.ResponseInfo.TotalRecords <= 0
        ) {
          throw new ApplicationNotFoundError();
        }

        return response;
      })
    );
  }

  // HttpClient API get() method => Fetch employees list
  getPosts(): Observable<Post[]> {
    return this.http
      .get<Post[]>(this.apiURL + '/posts')
      .pipe(retry(1), delay(1000), catchError(this.handleError));
  }

  // Error handling

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
