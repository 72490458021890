import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loan-document-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loan-document-confirmation.component.html',
  styleUrl: './loan-document-confirmation.component.scss'
})
export class LoanDocumentConfirmationComponent {

}
