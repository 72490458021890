import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-velocity-error',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './velocity-error.component.html',
  styleUrl: './velocity-error.component.scss',
})
export class VelocityErrorComponent {
  applicationId$: Observable<number>;

  constructor(private store: Store) {
    this.applicationId$ = this.store.pipe(
      select(LoanApplicationFeature.selectApplicationId)
    );
  }

  homeClick() {
    window.location.replace('/');
  }
}
