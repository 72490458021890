import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, mergeMap } from 'rxjs';

@Component({
  selector: 'app-loan-hkid-instruction',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './loan-hkid-instruction.component.html',
  styleUrl: './loan-hkid-instruction.component.scss',
})
export class LoanHkidInstructionComponent implements OnInit {
  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    console.log('ngOnInit');
  }
  open(content: TemplateRef<any>) {
    this.modalService.open(content);
  }

  startCapture(version: number) {
    this.store.dispatch(
      LoanApplicationActions.setVersion({
        version: version,
      })
    );

    this.modalService.dismissAll();
    this.router.navigate(['/loan-hkid-capture']);
  }
}
