import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

export const checkCameraExistGuard: CanActivateFn = (route, state) => {
  let url = inject(Router).parseUrl('error-no-camera-device');

  function hasCamera(): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          resolve(true); // Camera is available
        })
        .catch((error) => {
          // resolve(true); 
          resolve(url);
          // resolve(false); // Camera is not available
        });
    });
  }

  return hasCamera();
};
