import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ApiService,
  FileDescription,
} from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../shared/services/config.service';
import { Observable, first, map, switchMap } from 'rxjs';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { SharedModule } from '../../../shared/shared.module';
import { FileService } from '../../../shared/services/file.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-document-upload',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './loan-document-upload.component.html',
  styleUrl: './loan-document-upload.component.scss',
})
export class LoanDocumentUploadComponent implements OnInit {
  documentType: FileDescription = FileDescription.Income1;
  selectedFile: File | undefined;
  selectedFileString: string = '';
  isImageSelected = false;
  remark: string = '';

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private configService: ConfigService,
    private fileService: FileService,
    private errorHandlingService: ErrorHandlingService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.documentType = params['type'];
    });
  }

  onFileSelected(event: any) {
    this.modalService.dismissAll();
    this.selectedFile = event.target.files[0];
    this.isImageSelected = this.checkIfImage(event.target.files[0]);
    this.previewImage();
  }

  checkIfImage(file: File): boolean {
    const imageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    return imageTypes.includes(file.type);
  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content);
  }

  previewImage() {
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedFileString = reader.result as string;
    };
    reader.readAsDataURL(this.selectedFile!);
  }

  continue() {
    this.spinnerService.showSpinner();
    // retrieve the application id from store from upcoming operation

    const fileName =
      'file.' + this.fileService.getFileExtensionFromType(this.selectedFile!);

    this.uploadFile(
      this.selectedFileString,
      fileName,
      this.documentType,
      this.remark
    ).subscribe({
      next: (fileId) => {
        // result
        console.log(`result: ${fileId}`);
        console.log(fileId);

        // add file to store
        this.store.dispatch(
          LoanApplicationActions.addDocument({
            fileType: this.documentType,
            fileId: fileId,
          })
        );

        // go to document selection page
        this.router.navigate(['/loan-document-selection']);
      },
      error: (e) => {
        console.info('error');
        this.spinnerService.hideSpinner();
        this.errorHandlingService.handleError(e);
      },
      complete: () => {
        console.info('complete');
        this.spinnerService.hideSpinner();
      },
    });
  }

  uploadFile(
    file: string,
    fileName: string,
    documentType: FileDescription,
    remark: string
  ): Observable<number> {
    // add count for liveness attempt
    console.log('update file');

    return this.store.select(LoanApplicationFeature.selectInfo).pipe(
      first(),
      switchMap(({ applicationId }) =>
        this.apiService
          .createFileId(applicationId, documentType, fileName, remark)
          .pipe(
            switchMap((fileId) =>
              this.apiService
                .uploadFile(fileId, file)
                .pipe(map((result) => fileId))
            )
          )
      )
    );
  }
}
