<div class="container">
        <h6 class="text-center title-bold">{{"LOAN_DOCUMENT_SELECTION.HEADER" | translate}}</h6>

        <div class="diff-bg-container text-center">
                <div class="d-grid">
                        <a [routerLink]="['/loan-document-upload', '1stMonthIncomeStatement']"
                                [ngClass]="['btn-fileupload', isIncome1Uploaded ? 'uploaded' : '']">
                                <span>{{"LOAN_DOCUMENT_SELECTION.MIS1" | translate}}</span></a>

                        <a [routerLink]="['/loan-document-upload', '2ndMonthIncomeStatement']"
                                [ngClass]="['btn-fileupload', isIncome2Uploaded ? 'uploaded' : '']">
                                {{"LOAN_DOCUMENT_SELECTION.MIS2" | translate}}</a>

                        <a [routerLink]="['/loan-document-upload', '3rdMonthincomeStatement']"
                                [ngClass]="['btn-fileupload', isIncome3Uploaded ? 'uploaded' : '']">
                                {{"LOAN_DOCUMENT_SELECTION.MIS3" | translate}}</a>

                        <a [routerLink]="['/loan-document-upload','AddressProof']"
                                [ngClass]="['btn-fileupload', isAddressUploaded ? 'uploaded' : '']">
                                {{"LOAN_DOCUMENT_SELECTION.ADDRESS_PROOF" | translate}}</a>

                        <a [routerLink]="['/loan-document-upload','BankAccountHolderProof']"
                                [ngClass]="['btn-fileupload', isBankUploaded ? 'uploaded' : '']">
                                {{"LOAN_DOCUMENT_SELECTION.HOLDER_PROOF" | translate}}</a>

                        <a [routerLink]="['/loan-document-upload','OtherDocument']"
                                [ngClass]="['btn-fileupload', isOtherUploaded ? 'uploaded' : '']">
                                {{"LOAN_DOCUMENT_SELECTION.OTHER" | translate}}</a>
                </div>
        </div>
        <div class="d-grid gap-2">
                <button type="button" [disabled]="!allowSubmit" class="btn-blue"
                        (click)="submit()">{{"LOAN_DOCUMENT_SELECTION.SUBMIT" | translate}}</button>
        </div>

</div>