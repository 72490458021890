<div id="stepper" class="text-center">
    <ul *ngIf="router.url == '/'">
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-welcome'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-mobile'||
        router.url == '/loan-otp'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-hkid-instruction'||
        router.url == '/loan-hkid-capture'||
        router.url == '/loan-personal-detail'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-selfie-instruction'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-residential-address'||
        router.url == '/loan-advanced-info'||
        router.url == '/loan-employment-detail'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-confirmation'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-enter-application-id'||
        router.url == '/loan-second-otp'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-document-selection'||
        router.url.startsWith('/loan-document-upload')">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="progressing"></a></li>
    </ul>
    <ul *ngIf="router.url == '/loan-thank-you'">
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
        <li><a class="completed"></a></li>
    </ul>
</div>  