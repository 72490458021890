import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CalculatorService } from '../../../shared/services/calculator.service';
import { SharedModule } from '../../../shared/shared.module';
import { ConfigService } from '../../../shared/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { MartialStatus } from '../../../shared/interfaces/martial-status';
import { Store, select } from '@ngrx/store';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { Observable } from 'rxjs';
import { AprService } from '../../../shared/services/apr.service';
import { AprRow } from '../../../shared/interfaces/apr-row';

@Component({
  selector: 'app-loan-calculator',
  standalone: true,
  imports: [CommonModule, RouterLink, SharedModule, FormsModule],
  templateUrl: './loan-calculator.component.html',
  styleUrl: './loan-calculator.component.scss',
})
export class LoanCalculatorComponent {
  loanAmount: number = this.configService.getConfig().loan_amount.default;
  minLoanAmount: number = this.configService.getConfig().loan_amount.min;
  maxLoanAmount: number = this.configService.getConfig().loan_amount.max;
  loanAmountInterval: number =
    this.configService.getConfig().loan_amount.interval;
  aprTable: AprRow[] = this.configService.getConfig().apr_table;

  tenorValues: [number] =
    this.configService.getConfig().repayment_tenors.values;
  selectedValueIndex: number = this.tenorValues.indexOf(
    this.configService.getConfig().repayment_tenors.default
  );
  get tenor(): number {
    return this.tenorValues[this.selectedValueIndex];
  }

  constructor(
    private calculatorService: CalculatorService,
    private aprService: AprService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private translateService: TranslateService,
    private store: Store,
    private router: Router
  ) {
    const currentLanguage = this.translateService.currentLang;
    console.log('currentLanguage: ', currentLanguage);
  }

  get apr(): number | null {
    let apr = this.aprService.getApr(
      this.loanAmount,
      this.tenor,
      this.aprTable
    );
    return apr;
  }

  get monthlyInstallment(): number {
    return this.calculatorService.calculateMonthlyInstallment(
      this.loanAmount,
      this.tenor,
      this.apr!
    );
  }

  continueClick(): void {
    this.store.dispatch(
      LoanApplicationActions.setLoanAmountAndTenor({
        loanAmount: this.loanAmount,
        tenor: this.tenor,
      })
    );

    this.store
      .select(LoanApplicationFeature.selectLoanAmount)
      .subscribe((data) => {
        console.log('selectLoanAmount: ', data);
      });

    this.router.navigate(['/loan-welcome']);
  }

    ngOnInit() {
    const sliderEl = document.querySelector("#range1") as HTMLInputElement;
    const sliderE2 = document.querySelector("#range2") as HTMLInputElement;
    sliderEl.style.background = `linear-gradient(to right, #3CBBDD 1.66667%, rgb(233 236 239) 1.66667%)`;
    sliderE2.style.background = `linear-gradient(to right, #3CBBDD 9.09091%, rgb(233 236 239) 9.09091%)`;

  }

  onLoanAmountChange(): void {
    const sliderEl = document.querySelector("#range1") as HTMLInputElement;
    const sliderE2 = document.querySelector("#range2") as HTMLInputElement;

    sliderEl.addEventListener("input", (event: Event) => {
      const tempSliderValue1 = (event.target as HTMLInputElement).value;

      const progress1 = (parseInt(tempSliderValue1) / parseInt(sliderEl.max)) * 100;

      sliderEl.style.background = `linear-gradient(to right, #3CBBDD ${progress1}%, rgb(233 236 239) ${progress1}%)`;
    });

    sliderE2.addEventListener("input", (event: Event) => {
      const tempSliderValue2 = (event.target as HTMLInputElement).value;

      const progress2 = (parseInt(tempSliderValue2) / parseInt(sliderE2.max)) * 100;

      sliderE2.style.background = `linear-gradient(to right, #3CBBDD ${progress2}%, rgb(233 236 239) ${progress2}%)`;
    });
  }
  
}
