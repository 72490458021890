import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: any;

  constructor() { }

  public setConfig(config: any) {
    this.config = config;
  }

  public getConfig(): any {
    return this.config;
  }
}
