import {
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { finalize, tap } from 'rxjs';

export const loggingInterceptor: HttpInterceptorFn = (req, next) => {
  // console.log('loggingInterceptor request: ', req);
  // console.log('loggingInterceptor: ', req.url);
  const started = Date.now();
  let ok: string;
  var response: HttpEvent<any>;

  // return next(req);

  return next(req).pipe(
    tap({
      // Succeeds when there is a response; ignore other events
      next: (event) => {
        ok = event instanceof HttpResponse ? 'succeeded' : '';
        response = event;
      },
      // Operation failed; error is an HttpErrorResponse
      error: (_error) => (ok = 'failed'),
    }),
    // Log when response observable either completes or errors
    finalize(() => {
      // console.log('loggingInterceptor request ', req.url, Date.now());
      console.log('loggingInterceptor request', req);
      console.log('loggingInterceptor request headers', req.headers);
      console.log('loggingInterceptor request Content-Type', req.headers.get('Content-Type'));
      const elapsed = Date.now() - started;
      const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
      console.log('loggingInterceptor response', response);
    })
  );
};
