import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    RouterModule,
     HttpClientModule,
     TranslateModule,
     GoogleTagManagerModule,
     FormsModule
  ],
  exports: [
    RouterModule,
    TranslateModule,
    GoogleTagManagerModule,
    FormsModule
  ]
})
export class SharedModule { }
