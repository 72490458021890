import { Injectable } from '@angular/core';
import { AprRow } from '../interfaces/apr-row';

@Injectable({
  providedIn: 'root',
})
export class AprService {
  constructor() {}

  private isBetween(value: number, range: [number, number]): boolean {
    const [min, max] = range;
    return value >= min && value <= max;
  }

  /**
   *
   * @param loanAmount
   * @param tenorInMonths
   * @returns APR
   */
  getApr(
    loanAmount: number,
    tenorInMonths: number,
    aprTable: Array<AprRow>
  ): number | null {
    for (const aprRow of aprTable) {
      // console.log(aprRow)
      const { loan_amount_range, repayment_tenors_range, apr } = aprRow;

      // console.log(loanAmount);
      // console.log(loan_amount_range);
      // console.log(this.isBetween(loanAmount, loan_amount_range));

      // console.log(tenorInMonths);
      // console.log(repayment_tenors_range);
      // console.log(this.isBetween(tenorInMonths, repayment_tenors_range));
      if (
        this.isBetween(loanAmount, loan_amount_range) &&
        this.isBetween(tenorInMonths, repayment_tenors_range)
      ) {
        return apr;
      }
    }

    return null;
  }
}
