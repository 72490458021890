import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../shared/services/config.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import moment from 'moment';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-confirmation.component.html',
  styleUrl: './loan-confirmation.component.scss',
})
export class LoanConfirmationComponent implements OnInit {
  form!: FormGroup;
  loanAmount: number = 0;
  tenor: number = 0;
  basicInfo: any;
  homeAddress: any;
  advancedInfo: any;
  employmentInfo: any;

  residentialDataSource: any[] = [];
  maritalDataSource: any[] = [];
  educationDataSource: any[] = [];
  employmentStatusDataSource: any[] = [];
  industryDataSource: any[] = [];
  positionDataSource: any[] = [];

  constructor(
    private store: Store,
    private translate: TranslateService,
    private configService: ConfigService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private router: Router,
    private location: Location,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.residentialDataSource =
      this.configService.getConfig().residential_status;
    this.maritalDataSource = this.configService.getConfig().marital_status;
    this.educationDataSource = this.configService.getConfig().education_level;
    this.employmentStatusDataSource =
      this.configService.getConfig().employment_status;
    this.industryDataSource = this.configService.getConfig().business_nature;
    this.positionDataSource = this.configService.getConfig().occupation;
  }

  ngOnInit() {
    this.store
      .select(LoanApplicationFeature.selectInfoForConfirmation)
      .pipe(first())
      .subscribe({
        next: ({
          loanAmount,
          tenor,
          basicInfo,
          homeAddress,
          advancedInfo,
          employmentInfo,
        }) => {
          console.log('loanAmount', loanAmount);
          console.log('tenor', tenor);
          console.log('basicInfo', basicInfo);
          console.log('homeAddress', homeAddress);
          console.log('advancedInfo', advancedInfo);
          console.log('employmentInfo', employmentInfo);

          this.loanAmount = loanAmount;
          this.tenor = tenor;
          this.basicInfo = basicInfo;
          this.homeAddress = homeAddress;
          this.advancedInfo = advancedInfo;
          this.employmentInfo = employmentInfo;
        },
        error: (e) => {
          console.info('error');
          console.log(e.message);
        },
        complete: () => {},
      });

    this.initializeForm();
  }

  get residentialStatusText() {
    return this.residentialDataSource.find(
      (item) => item.code === this.advancedInfo.residentialStatus
    );
  }

  get maritalStatusText() {
    return this.maritalDataSource.find(
      (item) => item.code === this.advancedInfo.maritalStatus
    );
  }

  get educationLevelText() {
    return this.educationDataSource.find(
      (item) => item.code === this.advancedInfo.educationLevel
    );
  }

  get employmentStatusText() {
    return this.employmentStatusDataSource.find(
      (item) => item.code === this.employmentInfo.employmentStatus
    );
  }

  get industryText() {
    return this.industryDataSource.find(
      (item) => item.code === this.employmentInfo.industry
    );
  }

  get positionText() {

    if (this.employmentInfo.position === null) {
      return null;
    }

    return this.positionDataSource.find(
      (item) => item.code === this.employmentInfo.position
    );
  }

  get connectedPartyFormControl() {
    return this.form.get('connectedParty')!;
  }

  get eStatementFormControl() {
    return this.form.get('eStatement')!;
  }

  get clientThirdPartyConsentFormControl() {
    return this.form.get('clientThirdPartyConsent')!;
  }

  initializeForm() {
    this.form = new FormGroup({
      // connectedParty: new FormControl(false, [Validators.requiredTrue]),
      // eStatement: new FormControl(true, [Validators.requiredTrue]),
      connectedParty: new FormControl(false),
      eStatement: new FormControl(false),
      clientThirdPartyConsent: new FormControl(true, [Validators.requiredTrue]),
    });
  }

  backClick() {
    this.location.back();
  }

  continueClick(): void {
    this.spinnerService.showSpinner();
    this.store
      .select(LoanApplicationFeature.selectInfoForConfirmation)
      .pipe(
        first(),
        switchMap(
          ({
            applicationId,
            loanAmount,
            tenor,
            mobile,
            basicInfo,
            homeAddress,
            advancedInfo,
            employmentInfo,
            lengthOfEmploymentInMonths,
            isDirectMarketingAccepted,
          }) => {
            let w = window as any;
            let bb = w.blackbox;
            console.log('blackbox: ', bb);

            let data = {
              applicationId: applicationId,
              applicantFirstName: basicInfo.givenName,
              applicantLastName: basicInfo.familyName,
              dateOfBirth: moment(basicInfo.dateOfBirth, 'DD-MM-YYYY').format(
                'DDMMYYYY'
              ),
              title: basicInfo.title,
              idNumber: basicInfo.hkid,
              addressLine1: [
                homeAddress.unit,
                homeAddress.floor,
                homeAddress.block,
              ]
                .filter((item) => item !== '')
                .join(', '),
              addressLine2: homeAddress.building,
              addressLine3: homeAddress.district,
              addressLine4: homeAddress.area,
              telephoneCountryCode: '852',
              telephoneNumber: mobile,
              emailAddress: basicInfo.email,
              monthlyMortgageAmount: `${advancedInfo.monthlyMartgageAmount}`,
              monthlyRentalPayment: `${advancedInfo.monthlyRentalAmount}`,
              employmentStatus: employmentInfo.employmentStatus,
              occupationCode: employmentInfo.position,
              companyName: employmentInfo.companyName,
              businessNatureCode: employmentInfo.industry,
              lengthOfEmploymentInMonths: lengthOfEmploymentInMonths,
              yearsInCurrentProfession: '',
              monthlyIncome: `${employmentInfo.monthlyIncome}`,
              otherMonthlyIncome: '0',
              accountCode: '',
              blackbox: bb,
              statedIp: '',
              product: this.configService.getConfig().product_code,
              loanAmount: loanAmount,
              tenorInMonths: `${tenor}`,
              language: this.translate.currentLang === 'en' ? 'EN' : 'ZH', // ZH or ??
              applicationRequestDate: moment().format('DDMMYYYY'), // 01011990 // can enhance by store the date at the beginning rather than get current date everytime
              clientThirdPartyConsent: this.connectedPartyFormControl.value, // from loan-confirmation page
              directMarketing: isDirectMarketingAccepted, // from loan-welcome page
              eStatement: this.eStatementFormControl.value, // from loan-confirmation page
              maritalStatus: this.advancedInfo.maritalStatus,
              educationLevel: this.advancedInfo.educationLevel,
              residentialStatus: this.advancedInfo.residentialStatus,
            };

            return this.apiService.microFlowDOWeb(data);
          }
        )
      )
      .subscribe({
        next: (result) => {
          this.router.navigate(['/loan-document-selection']);
        },
        error: (e) => {
          this.spinnerService.hideSpinner();
          console.info('error');
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          this.spinnerService.hideSpinner();
        },
      });
  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }
}
