import {
  ActionReducer,
  MetaReducer,
  createFeature,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { LoanApplicationActions } from './loan-application.actions';
import { NewApplication } from '../interfaces/new-application';
import { PersonalDetail } from '../interfaces/personal-detail';
import { FileDescription } from '../services/api.service';

export const loanApplicationFeatureKey = 'loanApplication';

export interface State {
  token: string;
  loanAmount: number;
  tenor: number;
  isTermsAccepted: boolean;
  isPrivacyPolicyAccepted: boolean;
  isDisclaimerAccepted: boolean;
  isDirectMarketingAccepted: boolean;
  mobile: string;
  // newApplication: NewApplication;
  applicationId: number;
  version: number;
  fileIds: Array<number>;
  croppedImageId: number;
  imageQualityAssessmentAttempt: number;
  selfieFileId: number;
  passiveLivenessAttempt: number;
  facialRecognitionAttempt: number;
  // personalDetail: PersonalDetail;
  basicInfo: {
    title: string;
    fullName: string;
    familyName: string;
    givenName: string;
    dateOfBirth: string;
    hkid: string;
    chineseName: string;
    email: string;
  };
  homeAddress: {
    unit: string;
    floor: string;
    block: string;
    building: string;
    district: string;
    area: string;
    fullAddress: string;
  };
  advancedInfo: {
    residentialStatus: string;
    maritalStatus: string;
    educationLevel: string;
    monthlyMartgageAmount: number;
    monthlyRentalAmount: number;
  };
  employmentInfo: {
    employmentStatus: string;
    industry: string;
    companyName: string;
    position: string;
    monthlyIncome: number;
    workingYear: number;
    workingMonth: number;
  };
  documents: Array<{
    fileType: FileDescription;
    fileId: number;
  }>;

  // verticalFront: number;
  // inclinedFront: number;
  // verticalBack: number;
}

export const initialState: State = {
  token: '',
  loanAmount: 0,
  tenor: 0,
  isTermsAccepted: false,
  isPrivacyPolicyAccepted: false,
  isDisclaimerAccepted: false,
  isDirectMarketingAccepted: false,
  mobile: '',
  // newApplication: <NewApplication>{},
  applicationId: 0,
  version: 2018, // 2003 | 2018
  fileIds: [],
  croppedImageId: 0,
  imageQualityAssessmentAttempt: 0,
  selfieFileId: 0,
  passiveLivenessAttempt: 0,
  facialRecognitionAttempt: 0,
  // personalDetail: <PersonalDetail>{},
  basicInfo: {
    title: '',
    fullName: '',
    familyName: '',
    givenName: '',
    dateOfBirth: '',
    hkid: '',
    chineseName: '',
    email: '',
  },
  homeAddress: {
    unit: '',
    floor: '',
    block: '',
    building: '',
    district: '',
    area: '',
    fullAddress: '',
  },
  advancedInfo: {
    residentialStatus: '',
    maritalStatus: '',
    educationLevel: '',
    monthlyMartgageAmount: 0,
    monthlyRentalAmount: 0,
  },
  employmentInfo: {
    employmentStatus: '',
    industry: '',
    companyName: '',
    position: '',
    monthlyIncome: 0,
    workingYear: 0,
    workingMonth: 0,
  },
  documents: [],
  // verticalFront: 0,
  // inclinedFront: 0,
  // verticalBack: 0,
};

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);

    const nextState = reducer(state, action);

    console.log('Next state:', nextState);

    return nextState;
  };
}

export const metaReducers: MetaReducer<any>[] = [debug];

export const LoanApplicationFeature = createFeature({
  name: loanApplicationFeatureKey,
  reducer: createReducer(
    initialState,
    on(LoanApplicationActions.setToken, (state, action) => ({
      ...state,
      token: action.token,
    })),
    on(LoanApplicationActions.setLoanAmountAndTenor, (state, action) => ({
      ...state,
      loanAmount: action.loanAmount,
      tenor: action.tenor,
    })),
    on(LoanApplicationActions.setTerms, (state, action) => ({
      ...state,
      isTermsAccepted: action.isTermsAccepted,
      isPrivacyPolicyAccepted: action.isPrivacyPolicyAccepted,
      isDisclaimerAccepted: action.isDisclaimerAccepted,
      isDirectMarketingAccepted: action.isDirectMarketingAccepted,
    })),
    on(LoanApplicationActions.setMobile, (state, action) => ({
      ...state,
      mobile: action.mobile,
    })),
    // on(LoanApplicationActions.setNewApplication, (state, action) => ({
    //   ...state,
    //   newApplication: action.newApplication,
    // })),
    on(LoanApplicationActions.setApplicationId, (state, action) => ({
      ...state,
      applicationId: action.applicationId,
    })),
    on(LoanApplicationActions.setVersion, (state, action) => ({
      ...state,
      version: action.version,
    })),
    on(LoanApplicationActions.setFileIds, (state, action) => ({
      ...state,
      fileIds: action.fileIds,
    })),
    on(LoanApplicationActions.updateImageQualityAssessmentAttempt, (state) => ({
      ...state,
      imageQualityAssessmentAttempt: state.imageQualityAssessmentAttempt + 1,
    })),
    on(LoanApplicationActions.updatePassiveLivenessAttempt, (state) => ({
      ...state,
      passiveLivenessAttempt: state.passiveLivenessAttempt + 1,
    })),
    on(LoanApplicationActions.updateFacialRecognitionAttempt, (state) => ({
      ...state,
      facialRecognitionAttempt: state.facialRecognitionAttempt + 1,
    })),
    on(LoanApplicationActions.setSelfieFileId, (state, action) => ({
      ...state,
      selfieFileId: action.selfieFileId,
    })),
    on(LoanApplicationActions.setHomeAddress, (state, action) => ({
      ...state,
      homeAddress: {
        unit: action.unit,
        floor: action.floor,
        block: action.block,
        building: action.building,
        district: action.district,
        area: action.area,
        fullAddress: [action.unit, action.floor, action.block, action.building, action.district, action.area].filter(item => item !== '').join(', '),
      },
    })),
    on(LoanApplicationActions.setAdvancedInfo, (state, action) => ({
      ...state,
      advancedInfo: {
        residentialStatus: action.residentialStatus,
        maritalStatus: action.maritalStatus,
        educationLevel: action.educationLevel,
        monthlyMartgageAmount: action.monthlyMartgageAmount,
        monthlyRentalAmount: action.monthlyRentalAmount,
      },
    })),
    on(LoanApplicationActions.setEmploymentInfo, (state, action) => ({
      ...state,
      employmentInfo: {
        employmentStatus: action.employmentStatus,
        industry: action.industry,
        companyName: action.companyName,
        position: action.position,
        monthlyIncome: action.monthlyIncome,
        workingYear: action.workingYear,
        workingMonth: action.workingMonth,
      },
    })),
    // on(LoanApplicationActions.setPersonalDetail, (state, action) => (
    //   {
    //   ...state,
    //   personalDetail: action.personalDetail,
    // }))

    on(LoanApplicationActions.setBasicInfo, (state, action) => {
      const [familyName, givenName] = action.fullName.split(',');

      return {
        ...state,
        basicInfo: {
          title: action.title,
          fullName: action.fullName,
          familyName: familyName?.trim(),
          givenName: givenName?.trim(),
          dateOfBirth: action.dateOfBirth,
          hkid: action.hkid,
          chineseName: action.chineseName,
          email: action.email,
        },
      };
    }),
    on(LoanApplicationActions.addDocument, (state, action) => {
      return {
        ...state,
        documents: [
          ...state.documents,
          { fileType: action.fileType, fileId: action.fileId },
        ],
      };
    }),
  ),
  extraSelectors: ({
    selectApplicationId,
    selectVersion,
    selectFileIds,
    selectLoanAmount,
    selectTenor,
    selectBasicInfo,
    selectHomeAddress,
    selectAdvancedInfo,
    selectEmploymentInfo,
    selectMobile,
    selectIsDirectMarketingAccepted,
  }) => ({
    selectApplicationId: createSelector(
      selectApplicationId,
      (applicationId) => applicationId
    ),
    selectInfo: createSelector(
      selectApplicationId,
      selectVersion,
      (applicationId, version) => {
        return {
          applicationId: applicationId,
          version: version,
        };
      }
    ),
    selectInfoForFacialRecognition: createSelector(
      selectApplicationId,
      selectFileIds,
      (applicationId, fileIds) => {
        return {
          applicationId: applicationId,
          headFileId: fileIds.length > 0 ? fileIds[0] : 0,
        };
      }
    ),
    selectInfoForConfirmation: createSelector(
      selectLoanAmount,
      selectTenor,
      selectBasicInfo,
      selectHomeAddress,
      selectAdvancedInfo,
      selectEmploymentInfo,
      selectApplicationId,
      selectMobile,
      selectIsDirectMarketingAccepted,
      (
        loanAmount,
        tenor,
        basicInfo,
        homeAddress,
        advancedInfo,
        employmentInfo,
        applicationId,
        mobile,
        isDirectMarketingAccepted
      ) => {
        let lengthOfEmploymentInMonths =
          employmentInfo.workingYear * 12 + employmentInfo.workingMonth;

        return {
          // applicationId: newApplication.ResponseInfo.ApplicationId,
          applicationId: applicationId,
          loanAmount: loanAmount,
          tenor: tenor,
          mobile: mobile,
          isDirectMarketingAccepted: isDirectMarketingAccepted,
          basicInfo: basicInfo,
          homeAddress: homeAddress,
          advancedInfo: advancedInfo,
          employmentInfo: employmentInfo,
          lengthOfEmploymentInMonths: `${lengthOfEmploymentInMonths}`,
        };
      }
    ),
  }),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = LoanApplicationFeature;
