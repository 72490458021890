import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { Router, RouterLink } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SpinnerService } from '../../../shared/services/spinner.service';
import {
  ApiService,
  MaxVerifyOTPError,
} from '../../../shared/services/api.service';
import { Store } from '@ngrx/store';
import { combineLatest, first, switchMap } from 'rxjs';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { NgOtpInputModule } from 'ng-otp-input';
import { ConfigService } from '../../../shared/services/config.service';
import {
  CountdownComponent,
  CountdownEvent,
  CountdownModule,
} from 'ngx-countdown';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-second-otp',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    CountdownModule,
  ],
  templateUrl: './loan-second-otp.component.html',
  styleUrl: './loan-second-otp.component.scss',
})
export class LoanSecondOtpComponent implements OnInit {
    smsResendCountdown: number =
    this.configService.getConfig().sms_resend_countdown;
  @ViewChild('cd', { static: false }) private countdown!: CountdownComponent;
  isCountdowning: boolean = false;
  otp2Form!: FormGroup;
  mobile: string | undefined;

  ngOnInit(): void {
    this.initializeForm();
    this.resumeFormData();

    this.store
      .select(LoanApplicationFeature.selectMobile)
      .pipe(first())
      .subscribe({
        next: (mobile) => {
          this.mobile = mobile;
        },
        error: (e) => {
          console.info('error');
          console.log(e.message);
          window.alert(e.message);
        },
        complete: () => {
          console.info('complete');
        },
      });
  }

  get otp2FormControl() {
    return this.otp2Form.get('otp')!;
  }

  initializeForm() {
    this.otp2Form = new FormGroup({
      otp: new FormControl('', [Validators.required]),
    });
  }

  resumeFormData() {
    console.log('resumeFormData');
  }

  onOtpChange(otp: string) {
    console.log('otp: ' + otp);
    this.otp2FormControl.setValue(otp);
    this.otp2FormControl.markAsDirty();
    this.otp2FormControl.markAsTouched();
  }

  handleCountDownEvent(e: CountdownEvent) {
    console.log('Notify', e);

    if (e.status == 0) {
      this.isCountdowning = true;
    } else if (e.status == 3) {
      this.isCountdowning = false;
    }
  }

  continueClick(): void {
    // call validate otp api
    this.spinnerService.showSpinner();
    this.store
      .select(LoanApplicationFeature.selectApplicationId)
      .pipe(
        first(),
        switchMap((applicationId) => {
          return this.apiService.validateOTP(
            applicationId,
            this.otp2FormControl.value
          );
        })
      )
      .subscribe({
        next: (response) => {
          console.log('validateOTP: ', response);
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
          this.router.navigate(['/loan-document-selection']);
        },
      });
  }

  resendClick() {
    console.log('resendClick');

    this.countdown.restart();

    // call api to get otp
    this.spinnerService.showSpinner();

    combineLatest([
      this.store.select(LoanApplicationFeature.selectApplicationId),
      this.store.select(LoanApplicationFeature.selectMobile),
    ])
      .pipe(
        first(),
        switchMap(([applicationId, mobile]) => {
          return this.apiService.sendOTP(applicationId, mobile);
        })
      )
      .subscribe({
        next: (response) => {
          console.log('sendOTP: ', response);
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
        },
      });
  }

  backClick() {
    this.location.back();
  }
  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private location: Location,
    private configService: ConfigService,
    private errorHandlingService: ErrorHandlingService    
  ) {}
}
