<div class="container">
    <h6 class="text-center title-bold">{{"LOAN_CALCULATOR.SELECT" | translate}}</h6>

    <div class="diff-bg-container">
        <div class="mb-3">
            <div class="text-gray">{{"LOAN_CALCULATOR.AMOUNT" | translate}}</div>
            <div class="slidecontainer text-center">
                <h1>HK${{loanAmount | number}}</h1>
                <input type="range" class="range-input" id="range1" [min]="minLoanAmount" [max]="maxLoanAmount"
                    [(ngModel)]="loanAmount" required [step]="loanAmountInterval" (input)="onLoanAmountChange()">
            </div>
            <div class="row">
                <div class="col text-gray">HK${{minLoanAmount | number}}</div><div class="col text-right text-gray">HK${{maxLoanAmount | number}}</div>
            </div>
        </div>

        <div class="mb-3">
            <div class="text-gray">{{"LOAN_CALCULATOR.TENORS" | translate}}</div>
            <div class="slidecontainer text-center">
                <h1 class="title-bold">{{tenor}} {{"LOAN_CALCULATOR.MONTHS" | translate}}</h1>
                <input type="range" class="range-input " id="range2" [min]="0" [max]="tenorValues.length - 1"
                    [(ngModel)]="selectedValueIndex" (input)="onLoanAmountChange()">
            </div>
            <div class="row">
                <div class="col text-gray">{{tenorValues[0]}} {{"LOAN_CALCULATOR.MONTHS" | translate}}</div><div class="col text-right text-gray">{{tenorValues[tenorValues.length - 1]}} {{"LOAN_CALCULATOR.MONTHS" | translate}}</div>
            </div>
        </div>
    </div>
    <div class="diff-bg-container">
        <div class="mb-3">
            <div class="row">
                <div class="col-auto text-gray">{{"LOAN_CALCULATOR.LMR" | translate}}</div><div class="col text-right">HK$ {{monthlyInstallment | number}}</div>
            </div>
            <div class="row">
                <div class="col text-gray">{{"LOAN_CALCULATOR.APR" | translate}}</div><div class="col text-right">{{(apr ?? 0) }}%</div>
            </div>
        </div>
    </div>
    <div>
    </div>

    <div class="d-grid gap-2">
        <button href="javascript:;" class="btn-blue" (click)="continueClick()">
            {{"LOAN_CALCULATOR.CONTINUE" | translate}}
        </button>
        <button [routerLink]="['/loan-enter-application-id']" class="btn-white">
            {{"LOAN_CALCULATOR.UPLOAD_DOCUMENT" | translate}}
        </button>
    </div>

</div>