<app-header *ngIf="router.url !== '/loan-hkid-capture'"></app-header>
<main>
    <app-stepper *ngIf="router.url !== '/loan-hkid-capture' 
                    && router.url !== '/loan-index'
                    && router.url !=='/generic-error'
                    && router.url !=='/velocity-error'
                    && router.url !=='/error-non-mobile-device'
                    && router.url !=='/error-no-camera-device'
                    && router.url !=='/loan-maintain'"></app-stepper>
    <app-spinner *ngIf="showSpinner"></app-spinner>
    <router-outlet></router-outlet>
</main>

<app-footer *ngIf="router.url !== '/loan-hkid-capture'" (click)="handleFooterClick()"></app-footer>