import { Routes } from '@angular/router';
import { LoanIndexComponent } from './features/loan/loan-index/loan-index.component';
import { UploadIndexComponent } from './features/upload/upload-index/upload-index.component';
import { LoanCalculatorComponent } from './features/loan/loan-calculator/loan-calculator.component';
import { LoanWelcomeComponent } from './features/loan/loan-welcome/loan-welcome.component';
import { LoanOtpComponent } from './features/loan/loan-otp/loan-otp.component';
import { LoanMobileComponent } from './features/loan/loan-mobile/loan-mobile.component';
import { NonMobileDeviceComponent } from './features/error/non-mobile-device/non-mobile-device.component';
import { checkMobileDeviceGuard } from './shared/guards/check-mobile-device.guard';
import { checkCameraExistGuard } from './shared/guards/check-camera-exist.guard';
import { NoCameraDeviceComponent } from './features/error/no-camera-device/no-camera-device.component';
import { LoanHkidInstructionComponent } from './features/loan/loan-hkid-instruction/loan-hkid-instruction.component';
import { LoanHkidCaptureComponent } from './features/loan/loan-hkid-capture/loan-hkid-capture.component';
import { LoanPersonalDetailComponent } from './features/loan/loan-personal-detail/loan-personal-detail.component';
import { LoanSelfieInstructionComponent } from './features/loan/loan-selfie-instruction/loan-selfie-instruction.component';
import { LoanSelfieCaptureComponent } from './features/loan/loan-selfie-capture/loan-selfie-capture.component';
import { LoanResidentialAddressComponent } from './features/loan/loan-residential-address/loan-residential-address.component';
import { LoanPersonalDetailDemographicComponent } from './features/loan/loan-personal-detail-demographic/loan-personal-detail-demographic.component';
import { LoanEmploymentDetailComponent } from './features/loan/loan-employment-detail/loan-employment-detail.component';
import { LoanConfirmationComponent } from './features/loan/loan-confirmation/loan-confirmation.component';
import { UploadOtpComponent } from './features/upload/upload-otp/upload-otp.component';
import { LoanDocumentSelectionComponent } from './features/loan/loan-document-selection/loan-document-selection.component';
import { LoanDocumentUploadComponent } from './features/loan/loan-document-upload/loan-document-upload.component';
import { LoanDocumentConfirmationComponent } from './features/loan/loan-document-confirmation/loan-document-confirmation.component';
import { LoanThankYouComponent } from './features/loan/loan-thank-you/loan-thank-you.component';
import { TestComponent } from './features/test/test.component';
import { PageNotFoundComponent } from './features/error/page-not-found/page-not-found.component';
import { GenericErrorComponent } from './features/error/generic-error/generic-error.component';
import { configResolver } from './shared/resolvers/config.resolver';
import { LoanEnterApplicationIdComponent } from './features/loan/loan-enter-application-id/loan-enter-application-id.component';
import { LoanSecondOtpComponent } from './features/loan/loan-second-otp/loan-second-otp.component';
import { LoanMaintainComponent } from './features/loan/loan-maintain/loan-maintain.component';
import { checkPageRefreshGuard } from './shared/guards/check-page-refresh.guard';
import { VelocityErrorComponent } from './features/error/velocity-error/velocity-error.component';

export const routes: Routes = [
  {
    path: '',
    component: LoanCalculatorComponent,
    canActivate: [checkMobileDeviceGuard, checkCameraExistGuard],
  },

  {
    path: 'loan-welcome',
    component: LoanWelcomeComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-mobile',
    component: LoanMobileComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-otp',
    component: LoanOtpComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-hkid-instruction',
    component: LoanHkidInstructionComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-hkid-capture',
    component: LoanHkidCaptureComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'loan-personal-detail',
    component: LoanPersonalDetailComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-selfie-instruction',
    component: LoanSelfieInstructionComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-selfie-capture',
    component: LoanSelfieCaptureComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-residential-address',
    component: LoanResidentialAddressComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-advanced-info',
    component: LoanPersonalDetailDemographicComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'loan-employment-detail',
    component: LoanEmploymentDetailComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-confirmation',
    component: LoanConfirmationComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'loan-document-selection',
    component: LoanDocumentSelectionComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-document-upload/:type',
    component: LoanDocumentUploadComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-document-confirmation',
    component: LoanDocumentConfirmationComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-thank-you',
    component: LoanThankYouComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'velocity-error',
    component: VelocityErrorComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'generic-error',
    component: GenericErrorComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'upload',
    component: UploadIndexComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'upload-otp',
    component: UploadOtpComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'loan-enter-application-id',
    component: LoanEnterApplicationIdComponent,
    canActivate: [checkPageRefreshGuard],
  },
  {
    path: 'loan-second-otp',
    component: LoanSecondOtpComponent,
    canActivate: [checkPageRefreshGuard],
  },

  {
    path: 'error-non-mobile-device',
    component: NonMobileDeviceComponent,
  },
  {
    path: 'error-no-camera-device',
    component: NoCameraDeviceComponent,
  },
  {
    path: 'loan-maintain',
    component: LoanMaintainComponent,
    canActivate: [checkPageRefreshGuard],
  },

  // { path: 'test', component: TestComponent },
  { path: '**', component: PageNotFoundComponent },
];
