<div class="container">
  <h6 class="text-center title-bold">{{"LOAN_CONFIRMATION.HEADER" | translate}}</h6>

  <div class="diff-bg-container gray">
    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.AMOUNT" | translate}}</div>
      <div class="">${{loanAmount}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.REPAY_PERIOD" | translate}}</div>
      <div class="">{{tenor}} {{"LOAN_CONFIRMATION.REPAY_MONTH" | translate}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.ADDRESS" | translate}}</div>
      <div class="">{{homeAddress.fullAddress}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.RESIDENTIAL_STATUS" | translate}}</div>
      <div class="">{{residentialStatusText[getCurrentLocale()]}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.MARITAL_STATUS" | translate}}</div>
       <div class="">{{maritalStatusText[getCurrentLocale()]}}</div>
      
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.EDUCATION" | translate}}</div>
      <div class="">{{educationLevelText[getCurrentLocale()]}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.MONTH_MORTGAGE" | translate}}</div>
      <div class="">{{advancedInfo.monthlyMartgageAmount}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.MONTH_RENTAL" | translate}}</div>
      <div class="">{{advancedInfo.monthlyRentalAmount}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.EMPLOYMENT_STATUS" | translate}} </div>
      <div class="">{{employmentStatusText[getCurrentLocale()]}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.INDUSTRY" | translate}}</div>
      <div class="">{{industryText[getCurrentLocale()]}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.COMPANY" | translate}}</div>
      <div class="">{{employmentInfo.companyName}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.POSITION" | translate}}</div>
      <div class="">{{positionText ? positionText[getCurrentLocale()] : ' '}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.INCOME" | translate}}</div>
      <div class="">{{employmentInfo.monthlyIncome}}</div>
    </div>

    <div class="row mb-3">
      <div class="">{{"LOAN_CONFIRMATION.YEARS" | translate}}</div>
      <div class="">{{employmentInfo.workingYear}} {{"LOAN_CONFIRMATION.YEAR" | translate}}
        {{employmentInfo.workingMonth}} {{"LOAN_CONFIRMATION.MONTH" | translate}}</div>
    </div>
  </div>

  <form [formGroup]="form" class="size-10px container text-gray">

    <div class="mb-3">
      <div class="form-check d-none">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault1" formControlName="connectedParty">
        <label class="form-check-label" for="flexCheckDefault1">
          {{"LOAN_CONFIRMATION.CONNECTED_PARTY_MSG" | translate}}
        </label>
        <div
          *ngIf="connectedPartyFormControl.invalid && (connectedPartyFormControl.dirty || connectedPartyFormControl.touched)"
          class="alert alert-danger">
          <div *ngIf="connectedPartyFormControl.errors?.['required']">Connected Party is required.</div>
        </div>
      </div>

      <div class="form-check d-none">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault3" formControlName="eStatement">
        <label class="form-check-label" for="flexCheckDefault3">
          {{"LOAN_CONFIRMATION.STATEMENT_MSG" | translate}}
        </label>
        <div *ngIf="eStatementFormControl.invalid && (eStatementFormControl.dirty || eStatementFormControl.touched)"
          class="alert alert-danger">
          <div *ngIf="eStatementFormControl.errors?.['required']">{{"LOAN_CONFIRMATION.REQUIRE_3RDPARTY" | translate}}
          </div>
        </div>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault4"
          formControlName="clientThirdPartyConsent">
        <label class="form-check-label" for="flexCheckDefault4">
          {{"LOAN_CONFIRMATION.3RD_PARTY_MSG" | translate}}
        </label>
        <div
          *ngIf="clientThirdPartyConsentFormControl.invalid && (clientThirdPartyConsentFormControl.dirty || clientThirdPartyConsentFormControl.touched)"
          class="alert alert-danger">
          <div *ngIf="clientThirdPartyConsentFormControl.errors?.['required']">{{"LOAN_CONFIRMATION.REQUIRE_3RDPARTY" | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="d-grid gap-2">
      <button type="submit" class="btn-blue" [disabled]="!form.valid"
        (click)="continueClick()">{{"LOAN_CONFIRMATION.CONTINUE" | translate}}</button>
      <button type="" (click)="backClick()" class="btn-white">{{"LOAN_CONFIRMATION.BACK" | translate}}</button>
    </div>



  </form>

</div>