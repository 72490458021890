<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">

        <a class="navbar-brand" href="#">
            <img alt="" src="assets/img/rc_logo.png" class="img-fluid" style="height: 40px;">
        </a>
        
        <div> 
            <button class="ms-1" id="en" href="javascript:;" (click)="setLang('en')">EN</button>
            <button class="ms-1" id="zh-HK" href="javascript:;" (click)="setLang('zh-HK')">中文</button>
        </div>

    </div>
</nav>