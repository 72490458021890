import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loan-selfie-capture',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loan-selfie-capture.component.html',
  styleUrl: './loan-selfie-capture.component.scss'
})
export class LoanSelfieCaptureComponent {

}
