<div class="container">
  <h6 class="text-center title-bold">
    {{"LOAN_WELCOME.WELCOME" | translate}}
  </h6>
  <div class="diff-bg-container">

    <div class="text-center">
      <img alt="" class="img-fluid width-35" src="assets/img/icon_1.png">
    </div>

    <h5 class="text-center with-margin">{{"LOAN_WELCOME.PLEASESTART" | translate}}</h5>

    <div class="mb-3 size-10px">
      <form [formGroup]="welcomeForm">

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault1" formControlName="isTermsAccepted">
          <label class="form-check-label" for="flexCheckDefault1">
            {{"LOAN_WELCOME.RA" | translate}}<a class="title-bold" href="javascript:;"
              (click)="open(tncModal)">{{"LOAN_WELCOME.TC" | translate}}</a>
          </label>
          <div
            *ngIf="isTermsAcceptedFormControl.invalid && (isTermsAcceptedFormControl.dirty || isTermsAcceptedFormControl.touched)"
            class="alert alert-danger">
            <div *ngIf="isTermsAcceptedFormControl.errors?.['required']">{{"LOAN_WELCOME.REQUIRE_TERM" | translate}}
            </div>
          </div>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault2"
            formControlName="isPrivacyPolicyAccepted">
          <label class="form-check-label" for="flexCheckDefault2">
            {{"LOAN_WELCOME.RA" | translate}}<a class="title-bold" href="javascript:;"
              (click)="open(privacyPolicyModal)">{{"LOAN_WELCOME.PP" | translate}}</a>
          </label>
          <div
            *ngIf="isPrivacyPolicyAcceptedFormControl.invalid && (isPrivacyPolicyAcceptedFormControl.dirty || isPrivacyPolicyAcceptedFormControl.touched)"
            class="alert alert-danger">
            <div *ngIf="isPrivacyPolicyAcceptedFormControl.errors?.['required']">{{"LOAN_WELCOME.REQUIRE_PRIVACY" |
              translate}}</div>
          </div>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault4"
            formControlName="isDirectMarkingAccepted">
          <label class="form-check-label" for="flexCheckDefault4">
            {{"LOAN_WELCOME.CONSENT" | translate}}
          </label>
          <div
            *ngIf="isDirectMarkingAcceptedFormControl.invalid && (isDirectMarkingAcceptedFormControl.dirty || isDirectMarkingAcceptedFormControl.touched)"
            class="alert alert-danger">
            <div *ngIf="isDirectMarkingAcceptedFormControl.errors?.['required']">{{"LOAN_WELCOME.REQUIRE_MARKETING" |
              translate}}</div>
          </div>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault3" formControlName="isDisclaimerAccepted">
          <label class="form-check-label" for="flexCheckDefault3">
            {{"LOAN_WELCOME.DECLARE" | translate}}
          </label>
          <div
            *ngIf="isDisclaimerAcceptedFormControl.invalid && (isDisclaimerAcceptedFormControl.dirty || isDisclaimerAcceptedFormControl.touched)"
            class="alert alert-danger">
            <div *ngIf="isDisclaimerAcceptedFormControl.errors?.['required']">{{"LOAN_WELCOME.REQUIRE_DECLARE" |
              translate}}</div>
          </div>
        </div>

      </form>
    </div>
  </div>

  <div class="d-grid gap-2">
    <button type="submit" class="btn-blue" [disabled]="!welcomeForm.valid"
      (click)="continueClick()">{{"LOAN_WELCOME.START" |
      translate}}</button>
  </div>

</div>

<!-- TNC Modal -->
<ng-template #tncModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title title-bold title-underline centered" id="modal-basic-title">{{"LOAN_WELCOME.TC" | translate}}
    </h4>
  </div>
  <div class="modal-body">
    <div class="modal-body" [innerHTML]="'TNC_CONTENT' | translate">
    </div>
  </div>

  <div class="modal-footer with-btn-out1">
    <button type="button" class="btn-blue btn-out-boundary" (click)="modal.close('Ok click')">{{"LOAN_WELCOME.CLOSE" |
      translate}}</button>
  </div>
</ng-template>


<!-- Privacy Policy Modal -->
<ng-template #privacyPolicyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title title-bold title-underline centered" id="modal-basic-title">{{"LOAN_WELCOME.PP" | translate}}
    </h4>
  </div>
  <div class="modal-body">
    <div [innerHTML]="'PRIVACY_POLICY_CONTENT' | translate"></div>
  </div>
  <div class="modal-footer with-btn-out1">
    <button type="button" class="btn-blue btn-out-boundary" (click)="modal.close('Ok click')">{{"LOAN_WELCOME.CLOSE" |
      translate}}</button>
  </div>
</ng-template>