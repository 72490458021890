import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { Store } from '@ngrx/store';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import {
  ApiService,
  FileDescription,
} from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { switchMap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-welcome',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-welcome.component.html',
  styleUrl: './loan-welcome.component.scss',
})
export class LoanWelcomeComponent implements OnInit {
  welcomeForm!: FormGroup;

  ngOnInit() {
    this.initializeForm();
    // this.resumeFormData();

    // let w = window as any;
    // console.log('This is blackbox:', w.IGLOO.getBlackbox().blackbox);
  }

  get isTermsAcceptedFormControl() {
    return this.welcomeForm.get('isTermsAccepted')!;
  }

  get isPrivacyPolicyAcceptedFormControl() {
    return this.welcomeForm.get('isPrivacyPolicyAccepted')!;
  }

  get isDirectMarkingAcceptedFormControl() {
    return this.welcomeForm.get('isDirectMarkingAccepted')!;
  }

  get isDisclaimerAcceptedFormControl() {
    return this.welcomeForm.get('isDisclaimerAccepted')!;
  }

  initializeForm() {
    this.welcomeForm = new FormGroup({
      isTermsAccepted: new FormControl(true, [Validators.requiredTrue]),
      isPrivacyPolicyAccepted: new FormControl(true, [Validators.requiredTrue]),
      isDirectMarkingAccepted: new FormControl(true, [Validators.requiredTrue]),
      isDisclaimerAccepted: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  resumeFormData() {
    this.store
      .select(LoanApplicationFeature.selectIsTermsAccepted)
      .subscribe((data) => {
        this.isTermsAcceptedFormControl.setValue(data);
      });

    this.store
      .select(LoanApplicationFeature.selectIsPrivacyPolicyAccepted)
      .subscribe((data) => {
        this.isPrivacyPolicyAcceptedFormControl.setValue(data);
      });

    this.store
      .select(LoanApplicationFeature.selectIsDisclaimerAccepted)
      .subscribe((data) => {
        this.isDisclaimerAcceptedFormControl.setValue(data);
      });
  }

  open(content: TemplateRef<any>) {
    // this.modalService.open(content);
    this.modalService.open(content,{ scrollable: true });
  }

  continueClick(): void {
    console.log(
      `isDirectMarkingAcceptedFormControl: ${this.isDirectMarkingAcceptedFormControl.value}`
    );

    // save checkbox value to store
    this.store.dispatch(
      LoanApplicationActions.setTerms({
        isTermsAccepted: this.isTermsAcceptedFormControl.value,
        isPrivacyPolicyAccepted: this.isPrivacyPolicyAcceptedFormControl.value,
        isDisclaimerAccepted: this.isDisclaimerAcceptedFormControl.value,
        isDirectMarketingAccepted:
          this.isDirectMarkingAcceptedFormControl.value,
      })
    );

    // call token api
    this.spinnerService.showSpinner();
    this.apiService
      .getToken()
      .pipe(
        switchMap((token) => {
          // save token to store
          console.log('Token', token);
          this.store.dispatch(
            LoanApplicationActions.setToken({ token: token })
          );
          // call new application api
          return this.apiService.newApplication();
        })
      )
      .subscribe({
        next: (newApplication) => {
          // save new application to store
          console.log(
            'ApplicationId',
            newApplication.ResponseInfo.ApplicationId
          );
          this.store.dispatch(
            LoanApplicationActions.setApplicationId({
              applicationId: newApplication.ResponseInfo.ApplicationId,
            })
          );
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
          this.router.navigate(['/loan-mobile']);
        },
      });
  }

  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private errorHandlingService: ErrorHandlingService    

  ) {}
}
