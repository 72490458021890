import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../shared/services/config.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import moment from 'moment';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';

@Component({
  selector: 'app-loan-employment-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-employment-detail.component.html',
  styleUrl: './loan-employment-detail.component.scss',
})
export class LoanEmploymentDetailComponent implements OnInit {
  form!: FormGroup;
  employmentStatusDataSource: any[] = [];
  industryDataSource: any[] = [];
  positionDataSource: any[] = [];
  workingYearDataSource: number[] = [];
  workingMonthDataSource: number[] = [];

  constructor(
    private store: Store,
    private translate: TranslateService,
    private configService: ConfigService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.employmentStatusDataSource =
    this.configService.getConfig().employment_status;
    this.industryDataSource = this.configService.getConfig().business_nature;
    this.positionDataSource = this.configService.getConfig().occupation;
    this.workingYearDataSource = this.configService.getConfig().working_year;
    this.workingMonthDataSource = this.configService.getConfig().working_month;

    this.initializeForm();
  }

  // Form
  get employmentStatusFormControl() {
    return this.form.get('employmentStatus')!;
  }

  get industryFormControl() {
    return this.form.get('industry')!;
  }

  get companyNameFormControl() {
    return this.form.get('companyName')!;
  }

  get positionFormControl() {
    return this.form.get('position')!;
  }

  get monthlyIncomeFormControl() {
    return this.form.get('monthlyIncome')!;
  }

  get workingYearFormControl() {
    return this.form.get('workingYear')!;
  }

  get workingMonthFormControl() {
    return this.form.get('workingMonth')!;
  }

  initializeForm() {
    this.form = new FormGroup({
      employmentStatus: new FormControl('', [Validators.required]),
      industry: new FormControl('', [Validators.required]),
      // companyName: new FormControl('', [Validators.required]),
      // position: new FormControl('', [Validators.required]),
      companyName: new FormControl('', []),
      position: new FormControl('', []),
      monthlyIncome: new FormControl('', [Validators.required, Validators.min(0), Validators.max(9999999)]),
      workingYear: new FormControl('', [Validators.required]),
      workingMonth: new FormControl('', [Validators.required]),
    });
  }

  continueClick(): void {
    this.store.dispatch(
      LoanApplicationActions.setEmploymentInfo({
        employmentStatus: this.employmentStatusFormControl.value,
        industry: this.industryFormControl.value,
        companyName: this.companyNameFormControl.value,
        position: this.positionFormControl.value,
        monthlyIncome: this.monthlyIncomeFormControl.value,
        workingYear: this.workingYearFormControl.value,
        workingMonth: this.workingMonthFormControl.value,
      })
    );

    this.router.navigate(['/loan-confirmation']);
  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }

  backClick() {
    this.location.back();
  }
}
