import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, SharedModule, NgbCollapseModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
	isMenuCollapsed = true;
  constructor(private translate: TranslateService) {
  }

  locale : string | undefined;

  ngOnInit() {
    
    console.log("This locale is " + this.locale);
    // if(this.locale == null || this.locale == "undefined"){
    //   this.locale = "en";
    // }
    this.locale = this.translate.currentLang;
    const enBtn = document.getElementById('en');
    const zhBtn = document.getElementById('zh-HK');
    if(enBtn && zhBtn){
      if(this.locale == "en"){
        enBtn.style.display ='none';
        zhBtn.style.display ='inline-block';
        this.setLang("en");
      }
      else{
        enBtn.style.display ='inline-block';
        zhBtn.style.display ='none';
        this.setLang("zh-HK");
      }
    }

  }
  public setLang(lang: string) {
    this.translate.use(lang);
    this.hideBtn(lang);
    localStorage.setItem('locale', lang);
  }

  private hideBtn(lang : string){
    const enBtn = document.getElementById('en');
    const zhBtn = document.getElementById('zh-HK');
    if(enBtn && zhBtn){
      if(lang == "en"){
        enBtn.style.display ='none';
        zhBtn.style.display ='inline-block';
      }
      else{
        enBtn.style.display ='inline-block';
        zhBtn.style.display ='none';
      }
    }
  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }

}
