import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import VConsole from 'vconsole';
import { Store } from '@ngrx/store';
import { NavigationStart, Router } from '@angular/router';
import {
  ApiService,
  DocAuthLiteError,
  FileDescription,
  ImageQualityAssessmentError,
} from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import {
  Observable,
  catchError,
  concat,
  concatMap,
  delay,
  first,
  forkJoin,
  map,
  mergeMap,
  of,
  retry,
  switchMap,
  take,
  tap,
  throwError,
  timer,
  toArray,
} from 'rxjs';
import { ImageService } from '../../../shared/services/image.service';
import {
  WebSdkResult,
  WebSdkResultCode,
  WebSdkResultStep,
} from '../../../shared/interfaces/web-sdk-result';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { ConfigService } from '../../../shared/services/config.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-loan-hkid-capture',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './loan-hkid-capture.component.html',
  styleUrls: [
    './loan-hkid-capture.component.scss',
    '/src/assets/css/index.css',
  ],
})
export class LoanHkidCaptureComponent implements OnInit {
  step: string = '';
  images: Array<WebSdkResult> = [];
  version: number = 2018;
  resultString$: Observable<string | Object> | undefined;
  @ViewChild('retryModal') retryModal!: TemplateRef<any>;
  sdk: any;
  applicationId: number = 0;
  isProduction: boolean = environment.production;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private imageService: ImageService,
    private location: Location,
    private configService: ConfigService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  getTranslatedValue(
    key: string,
    interpolateParams?: object
  ): Observable<string | Object> {
    return this.translate.get(key, interpolateParams);
  }

  ngOnInit() {
    // Unmount the SDK when leave this page
    this.router.events.subscribe((event) => {
      console.log(event);
      if (event instanceof NavigationStart) {
        // Check if the user is navigating back or leaving the page
        if (event.navigationTrigger === 'popstate') {
          // Back button was clicked
          console.log('Back button clicked');
          // Perform your desired actions here
          this.sdk.unmount();
        } else if (event.navigationTrigger === 'imperative') {
          // Leaving the page programmatically
          console.log('Leaving the page');
          // Perform your desired actions here
          this.sdk.unmount();
        }
      }
    });

    this.store
      .select(LoanApplicationFeature.selectVersion)
      .pipe(first())
      .subscribe({
        next: (version) => {
          this.version = version;
          this.initSdk(version);
        },
        error: (e) => {
          console.info('error');
          console.log(e.message);
        },
        complete: () => {
          console.info('complete');
        },
      });

    // let w = window as any;
    // console.log(w.daLite);
    // console.log(w.daLite.type.RESULT);

    // this.sdk = new w.daLite({
    //   // certiType: 2,
    //   certiType: this.version === 2003 ? 1 : 2,
    //   rectangleContainer: document.getElementById('caparea'),
    // });

    // this.sdk.setup('assets/dalite_sim_next.onnx').then((data: any) => {
    //   //  Client
    //   console.log('data');
    //   console.log(data);
    //   const devices = this.sdk.devices;
    //   console.log(devices);

    //   this.sdk.step = 1;
    //   if (data.success) {
    //     this.sdk.addListeners(w.daLite.type.RESULT, [
    //       (result: WebSdkResult) => {
    //         if (result.code == WebSdkResultCode.OK) {
    //           console.log('step', result.step);
    //           console.log('nextStep', result.nextStep);

    //           // store the image into an array for futher process
    //           this.images.push(result);

    //           // all image captured
    //           if (result.nextStep === WebSdkResultStep.STEP4) {
    //             this.verifyProcess();
    //           }
    //         }

    //         let key = 'WEB_SDK.' + result.code;
    //         this.resultString$ = this.getTranslatedValue(key);
    //       },
    //     ]);
    //     this.sdk.addListeners(w.daLite.type.NEXTSTEP, [
    //       (message: any) => {
    //         // subscribe to

    //         if (message['step'] == 2) {
    //           document.getElementById('img')!.id = 'img1';
    //           document.getElementById('img2')!.id = 'img';
    //         }
    //         if (message['step'] == 3) {
    //           document.getElementById('img')!.id = 'img2';
    //           document.getElementById('img3')!.id = 'img';
    //         }
    //         if (message['step'] == 4) {
    //           // this.verifyProcess();
    //         }
    //       },
    //     ]);
    //     this.sdk.start(); //  start shooting
    //     console.log('sdk: start');
    //   } else {
    //     console.log('failed');
    //     console.log(data);
    //   }
    // });
  }

  initSdk(version: number) {
    let w = window as any;
    console.log(w.daLite);
    console.log(w.daLite.type.RESULT);

    this.sdk = new w.daLite({
      // certiType: 2,
      certiType: version === 2003 ? 1 : 2,
      rectangleContainer: document.getElementById('caparea'),
    });

    this.sdk.setup('assets/dalite_sim_next.onnx').then((data: any) => {
      //  Client
      console.log('data');
      console.log(data);
      const devices = this.sdk.devices;
      console.log(devices);

      this.sdk.step = 1;
      if (data.success) {
        this.sdk.addListeners(w.daLite.type.RESULT, [
          (result: WebSdkResult) => {
            if (result.code == WebSdkResultCode.OK) {
              console.log('step', result.step);
              console.log('nextStep', result.nextStep);

              // store the image into an array for futher process
              this.images.push(result);

              // all image captured
              if (result.nextStep === WebSdkResultStep.STEP4) {
                this.verifyProcess();
              }
            } else if (result.code == WebSdkResultCode.LOOSE) {
              console.log('LOOSE');
              this.back();
            }

            let key = 'WEB_SDK.' + result.code;
            this.resultString$ = this.getTranslatedValue(key);
          },
        ]);
        this.sdk.addListeners(w.daLite.type.NEXTSTEP, [
          (message: any) => {
            // subscribe to

            console.log('NEXTSTEP: ' + message['step']);

            if (message['step'] == 2) {
              document.getElementById('img')!.id = 'img1';
              document.getElementById('img2')!.id = 'img';
            }
            if (message['step'] == 3) {
              document.getElementById('img')!.id = 'img2';
              document.getElementById('img3')!.id = 'img';
            }
            if (message['step'] == 4) {
              // this.verifyProcess();
            }
          },
        ]);
        this.sdk.start(); //  start shooting
        console.log('sdk: start');
      } else {
        console.log('failed');
        console.log(data);
      }
    });
  }

  verifyProcess() {
    // upload file
    // image quality assessment
    // save no of attempt of image quality assessment, if <= 10 , try again, otherwise go to da lite
    // if all pass , go to da lite
    // DA Lite
    // if pass, go to ocr
    // if fail, try again, if attempt > 5, go to ocr
    // OCR

    const processAllImages = (applicationId: number) => {
      // Update the attempt count in the store
      this.store.dispatch(
        LoanApplicationActions.updateImageQualityAssessmentAttempt()
      );

      return this.images.map((image) => {
        return this.uploadFileAndQualityAssessment(applicationId, image.image);
      });
    };

    const imageQualityAssessmentAllImages = (
      applicationId: number,
      fileIds: Array<number>
    ) => {
      return fileIds.map((fileId) => {
        return this.apiService
          .imageQualityAssessment(applicationId, fileId)
          .pipe(
            catchError((error) =>
              this.handleImageQualityAssessmentError(error)
            ),
            map((result) => fileId)
          );
      });
    };

    this.spinnerService.showSpinner();
    // retrieve the application id from store from upcoming operation
    this.store
      .select(LoanApplicationFeature.selectInfo)
      .pipe(
        first(),
        switchMap(({ applicationId, version }) =>
          // wrap the all operation in order to use the application Id from store
          // process all the images
          concat(...processAllImages(applicationId)).pipe(
            toArray(),
            switchMap((fileIds) =>
              // forkJoin(imageQualityAssessmentAllImages(applicationId, fileIds))
              concat(
                ...imageQualityAssessmentAllImages(applicationId, fileIds)
              ).pipe(toArray())
            ),
            switchMap((fileIds) =>
              this.docAuthLite(applicationId, fileIds, version)
            ),
            switchMap((croppedImageId) =>
              this.docAuthOcr(applicationId, version, croppedImageId)
            )
          )
        )
      )
      .subscribe({
        next: (result) => {
          // result
          console.log(`result: ${result}`);
          console.log(result);

          let personalDetail =
            result.Fields.Applicants_IO.Applicant[0].Attributes.DSDocAuthOCR;

          this.store.dispatch(
            LoanApplicationActions.setBasicInfo({
              title: '',
              fullName: personalDetail.FullName,
              dateOfBirth: personalDetail.BirthDate,
              // hkid: personalDetail.DocumentNumber,
              hkid: personalDetail.DocumentNumber.replace("(", "").replace(")", ""),
              chineseName: personalDetail.FullNameCN,
              email: '',
            })
          );

          this.router.navigate(['/loan-personal-detail']);
        },
        error: (e) => {
          console.info('error');

          this.spinnerService.hideSpinner();
          if (e instanceof ImageQualityAssessmentError) {
            console.log('catch error ImageQualityAssessmentError');
            this.open(this.retryModal);
          } else if (e instanceof DocAuthLiteError) {
            console.log(
              'catch error DocAuthLiteError, this error occur and catched here, most likely no cropped image id returned.'
            );
            this.back();
          } else {
            this.errorHandlingService.handleError(e);
          }
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
        },
      });
  }

  uploadFileAndQualityAssessment(
    applicationId: number,
    file: string
  ): Observable<number> {
    const extension = this.imageService.getFileExtensionFromDataURL(file);

    console.log('fileExtension: ', extension);
    return this.apiService
      .createFileId(
        applicationId,
        FileDescription.IdDocument,
        'HKID.' + extension,
        'Image of the ID card'
      )
      .pipe(
        switchMap((fileId) =>
          this.apiService.uploadFile(fileId, file).pipe(map((result) => fileId))
        )
      );
  }

  handleImageQualityAssessmentError(error: Error) {
    return this.store
      .select(LoanApplicationFeature.selectImageQualityAssessmentAttempt)
      .pipe(
        first(),
        mergeMap((attempt) => {
          let maxAttempt =
            this.configService.getConfig().image_quality_assessment_attempt;
          console.log(`current attempt: ${attempt}`);
          console.log(`max attempt: ${maxAttempt}`);
          if (attempt >= maxAttempt) {
            return of(true);
          } else {
            throw error;
          }
        })
      );
  }

  docAuthLite(applicationId: number, fileIds: Array<number>, version: number) {
    // all success, store fileid to store and process dalite
    this.store.dispatch(
      LoanApplicationActions.setFileIds({
        fileIds: fileIds,
      })
    );

    return this.apiService.docAuthLite(applicationId, fileIds, version).pipe(
      retry({
        count: this.configService.getConfig().da_lite_attempt - 1,
        delay: (_error, retryIndex) => {
          if (_error instanceof DocAuthLiteError) {
            return timer(1000);
          }
          throw _error;
        },
      }), // retry
      catchError((error) => {
        // return true after max retry
        console.log(`reach max da_lite_attempt`);

        // check if it is DocAuthLiteError
        if (error instanceof DocAuthLiteError) {
          if (error.croppedImageId) {
            return of(error.croppedImageId);
          } else {
            throw error;
          }
        }

        // directly throw the error if not DocAuthLiteError
        throw error;
      })
    );
  }

  docAuthOcr(applicationId: number, version: number, croppedImageId: number) {
    return this.store.select(LoanApplicationFeature.selectFileIds).pipe(
      first(),
      switchMap((fileids) => {
        // return this.apiService.docAuthOcr(applicationId, fileids[0], version);
        return this.apiService.docAuthOcr(
          applicationId,
          croppedImageId,
          version
        );
      })
    );
  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdrop: 'static', // Disable dismiss on backdrop click
    });
  }

  back() {
    this.location.back();
  }

  // for testing purpose only
  onFileSelected(event: any, index: number) {
    const reader = new FileReader();
    reader.onload = () => {
      console.log('reader.result: ', reader.result as string);
      const myObject: WebSdkResult = {
        code: `${index}`,
        step: 123,
        nextStep: 123,
        message: '123',
        preview: '123',
        image: reader.result as string,
      };

      this.images[index] = myObject;
      console.log(this.images);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  submit() {
    this.verifyProcess();
  }

  skip() {
    this.sdk.start();

    console.log(
      'skip..................................................................'
    );
    const myObject: WebSdkResult = {
      code: '123',
      step: 123,
      nextStep: 123,
      message: '123',
      preview: '123',
      image: 'data:image/png;base64,iVBORw0KGgoCYII=',
    };
    this.images.push(myObject);
    this.images.push(myObject);
    this.images.push(myObject);

    this.verifyProcess();
  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }
}
