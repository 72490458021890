<div class="container">

    <h6 class="text-center title-bold" *ngIf="!webcamImage">{{"LOAN_SELFIE_INSTRUCTION.HEADER" | translate}}</h6>

    <div class="diff-bg-container" *ngIf="!webcamImage">
        <div class="wrapper" (window:resize)="onResize($event)" *ngIf="showWebcam" >
            <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)">
        </webcam>

        <!-- <webcam [height]="width" [width]="width" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)">
    </webcam> -->
        </div>

        <div *ngIf="error">error: {{ error}}</div>
        <div class="d-grid gap-2 selfie text-center" *ngIf="!showWebcam">
            <a href="javascript:;" class="" (click)="open(selfieModal)">
                <div class="text-center with-margin">
                    <img alt="" class="img-fluid width-35 mt-0" src="assets/img/icon_3_camera.png">
                </div>
                <h5 class="with-margin">{{"LOAN_SELFIE_INSTRUCTION.TAKE_WITH_CAMERA" | translate}}</h5>
                <h6 class="with-margin">{{"LOAN_SELFIE_INSTRUCTION.TAKE_WITH_CAMERA_HINT" | translate}}</h6>
            </a>
        </div>
        <div class="d-grid gap-2 selfie" *ngIf="showWebcam">
            <button href="javascript:;" class="btn-blue" (click)="triggerSnapshot()">{{"LOAN_SELFIE_INSTRUCTION.CAPTURE" | translate}}</button>
        </div>
    </div>
    <div class="text-center size-10px" *ngIf="!webcamImage">
        {{"LOAN_SELFIE_INSTRUCTION.DISCLAIMER1" | translate}}<br>
        {{"LOAN_SELFIE_INSTRUCTION.DISCLAIMER2" | translate}}
    </div>

    <h6 class="text-center title-bold" *ngIf="webcamImage">{{"LOAN_SELFIE_INSTRUCTION.HEADER_CONFIRM" | translate}}</h6>
    <div class="snapshot container" *ngIf="webcamImage">

        <img alt="" [src]="flippedImage" class="img-fluid mb-3" />
        <div class="d-grid gap-2">
            <button href="javascript:;" class="btn-blue" (click)="reset()">{{"LOAN_SELFIE_INSTRUCTION.RETAKE" |
                translate}}</button>
            <button href="javascript:;" class="btn-blue" (click)="continue()">{{"LOAN_SELFIE_INSTRUCTION.CONTINUE" |
                translate}}</button>
        </div>
    </div>
    <div class="text-center size-10px mt-3" *ngIf="webcamImage">
        {{"LOAN_SELFIE_INSTRUCTION.DISCLAIMER1" | translate}}<br>
        {{"LOAN_SELFIE_INSTRUCTION.DISCLAIMER2" | translate}}
    </div>

    <div style="position: absolute;top:110%;left:0;" *ngIf="!isProduction">
        img1: <input type="file" id="upload1" (change)="onFileSelected($event)" /> <br>
        <a href="javascript:;" (click)="submit()">submit</a><br>

    </div>

</div>


<!-- 2018 Modal -->
<ng-template #selfieModal let-modal>

    <div class="modal-header header-with-cross">
        <div class="cross-click header-with-cross">
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="header-with-cross text-center">
            <h5 class="modal-title title-bold" id="modal-basic-title">{{"LOAN_SELFIE_INSTRUCTION.INSTRUCTION_HEADER" |
                translate}}</h5>
        </div>
    </div>

    <div class="modal-body">
        <img alt="" class="image-center w-100" src="assets/img/InstructionforSelfie.png">
        <div class="w-75 text-center centered mt-3 mb-3">
            <div class="title-bold">{{"LOAN_SELFIE_INSTRUCTION.INSTRUCTION_MSG1" | translate}}</div>
            <hr class="hr-black">

            <span class="text-blue">
                {{"LOAN_SELFIE_INSTRUCTION.INSTRUCTION_MSG2" | translate}}<br>
                {{"LOAN_SELFIE_INSTRUCTION.INSTRUCTION_MSG3" | translate}}<br>
                {{"LOAN_SELFIE_INSTRUCTION.INSTRUCTION_MSG4" | translate}}
            </span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn-lightblue" (click)="start()">{{"LOAN_SELFIE_INSTRUCTION.CONTINUE" |
            translate}}</button>
    </div>
</ng-template>