import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router, RouterLink } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { finalize, first, switchMap, take } from 'rxjs';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-mobile',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-mobile.component.html',
  styleUrl: './loan-mobile.component.scss',
})
export class LoanMobileComponent implements OnInit {
  mobileForm!: FormGroup;

  ngOnInit() {
    this.initializeForm();
    this.resumeFormData();
  }

  get mobileFormControl() {
    return this.mobileForm.get('mobile')!;
  }

  initializeForm() {
    this.mobileForm = new FormGroup({
      mobile: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[4-9]\d{7}$/),
      ]),
    });
  }

  resumeFormData() {
    console.log('resumeFormData');
  }

  continueClick(): void {
    // save mobile to store
    this.store.dispatch(
      LoanApplicationActions.setMobile({
        mobile: this.mobileFormControl.value,
      })
    );

    // call api to get otp
    this.spinnerService.showSpinner();
    this.store
      .select(LoanApplicationFeature.selectApplicationId)
      .pipe(
        first(),
        switchMap((applicationId) => {
          return this.apiService.sendOTP(
            applicationId,
            this.mobileFormControl.value
          );
        })
      )
      .subscribe({
        next: (response) => {
          console.log('sendOTP: ', response);
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
          this.router.navigate(['/loan-otp']);
        },
      });

    // go to otp page
  }

  backClick() {
    window.location.replace('/');
  }

  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private location: Location,
    private errorHandlingService: ErrorHandlingService
  ) {}
}
