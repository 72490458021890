<div>
    <div class="text-center with-margin">
        {{"LOAN_INDEX.NOTIFICATION1" | translate}}<br>
        {{"LOAN_INDEX.NOTIFICATION2" | translate}}<br>
        {{"LOAN_INDEX.ENTER_LINK" | translate}}
    </div>

    <div class="image-center">
        <img alt="" src="assets/img/sample_QR_Code.jpg">
    </div>

    <div class="text-center with-margin">
        {{"LOAN_INDEX.ENTER_LINK" | translate}}
    
        <div class="content_in_one_line">
            <span class="text_for_copy diff-bg" id="webLink">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</span><span class="diff-bg" (click)="copyToClipboard()">C&P</span>
        </div>
    </div>
</div>