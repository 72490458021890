<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_SECOND_OTP.HEADER" | translate}}</h6>
    <div class="diff-bg-container h-50vh">
        <p class="with-margin text-center">{{"LOAN_SECOND_OTP.INSTRUCTION" | translate: { value: mobile} }}</p>

        <form [formGroup]="otp2Form" class="mb-3">

            <div class="form-group otp-inputs">

                <ng-otp-input class="text-center" (onInputChange)="onOtpChange($event)"
                    [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>
                <input type="text" class="form-control w-100 d-none" placeholder="otp" formControlName="otp" required>
                <div *ngIf="otp2FormControl.invalid && (otp2FormControl.dirty || otp2FormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="otp2FormControl.errors?.['required']">{{"LOAN_SECOND_OTP.REQUIRE_OTP" | translate}}
                    </div>
                </div>
            </div>

        </form>
        <div class="text-center">
            <div [hidden]="!isCountdowning" class="text-blue">
                <countdown #cd [config]="{leftTime: smsResendCountdown, format:'ss'}" (event)="handleCountDownEvent($event)"></countdown> s
            </div>
            <a [hidden]="isCountdowning" class="resend text-center" href="javascript:;"
                (click)="resendClick()">{{"LOAN_SECOND_OTP.RESEND" | translate}}</a>
        </div>

    </div>


    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!otp2Form.valid"
            (click)="continueClick()">{{"LOAN_SECOND_OTP.CONTINUE" | translate}}</button>
        <button class="btn-white" (click)="backClick()">{{"LOAN_SECOND_OTP.BACK" | translate}}</button>
    </div>

</div>