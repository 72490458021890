import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { first, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoanApplicationFeature } from '../store/loan-application.reducer';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  console.log('tokenInterceptor: ', req.url);

  if (req.url.startsWith(environment.apiUrl)) {
    const endpoint = req.url.replace(environment.apiUrl, '');

    switch (endpoint) {
      case '/TU.DE.Pont/token':
        break;

      default:
        return inject(Store)
          .select(LoanApplicationFeature.selectToken)
          .pipe(
            first(),
            switchMap((token) => {
              const clonedRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                },
              });

              return next(clonedRequest);
            })
          );
    }
  }

  return next(req);
};
