import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { NewApplication } from '../interfaces/new-application';
import { PersonalDetail } from '../interfaces/personal-detail';
import { FileDescription } from '../services/api.service';

export const LoanApplicationActions = createActionGroup({
  source: 'LoanApplication/API',
  events: {
    setToken: props<{ token: string }>(),
    setLoanAmountAndTenor: props<{ loanAmount: number; tenor: number }>(),
    setTerms: props<{
      isTermsAccepted: boolean;
      isPrivacyPolicyAccepted: boolean;
      isDisclaimerAccepted: boolean;
      isDirectMarketingAccepted: boolean;
    }>(),
    // setNewApplication: props<{
    //   newApplication: NewApplication;
    // }>(),
    setApplicationId: props<{
      applicationId: number;
    }>(),
    setMobile: props<{
      mobile: string;
    }>(),
    // setVerticalFront: props<{
    //   verticalFront: number;
    // }>(),
    // setInclinedFront: props<{
    //   inclinedFront: number;
    // }>(),
    // setVerticalBack: props<{
    //   verticalBack: number;
    // }>(),
    setFileIds: props<{
      fileIds: Array<number>;
    }>(),
    setVersion: props<{
      version: number;
    }>(),
    updateImageQualityAssessmentAttempt: emptyProps(),
    // setPersonalDetail: props<{
    //   personalDetail: PersonalDetail;
    // }>(),

    setBasicInfo: props<{
      title: string;
      fullName: string;
      dateOfBirth: string;
      hkid: string;
      chineseName: string;
      email: string;
    }>(),
    updatePassiveLivenessAttempt: emptyProps(),
    updateFacialRecognitionAttempt: emptyProps(),
    setSelfieFileId: props<{
      selfieFileId: number;
    }>(),
    setHomeAddress: props<{
      unit: string;
      floor: string;
      block: string;
      building: string;
      district: string;
      area: string;
    }>(),
    setAdvancedInfo: props<{
      residentialStatus: string;
      maritalStatus: string;
      educationLevel: string;
      monthlyMartgageAmount: number;
      monthlyRentalAmount: number;
    }>(),
    setEmploymentInfo: props<{
      employmentStatus: string;
      industry: string;
      companyName: string;
      position: string;
      monthlyIncome: number;
      workingYear: number;
      workingMonth: number;
    }>(),
    addDocument: props<{
      fileId: number;
      fileType: FileDescription;
    }>(),
    // 'Add LoanApplication': props<{ loanApplication: LoanApplication }>(),
    // 'Upsert LoanApplication': props<{ loanApplication: LoanApplication }>(),
    // 'Add LoanApplications': props<{ loanApplications: LoanApplication[] }>(),
    // 'Upsert LoanApplications': props<{ loanApplications: LoanApplication[] }>(),
    // 'Update LoanApplication': props<{ loanApplication: Update<LoanApplication> }>(),
    // 'Update LoanApplications': props<{ loanApplications: Update<LoanApplication>[] }>(),
    // 'Delete LoanApplication': props<{ id: string }>(),
    // 'Delete LoanApplications': props<{ ids: string[] }>(),
    // 'Clear LoanApplications': emptyProps(),
  },
});
