<div class="container">
    <div class="diff-bg-container text-center container-lg">
        <div>
            <p class="with-margin w-100">
                {{"LOAN_ERROR_MSG" | translate}}
            </p>

            <P class="with-margin">{{"APP_ID" | translate}}</P>
            <p class="with-margin">{{ applicationId$ | async }}</p>
            <button class="btn-blue with-margin" (click)="homeClick()">{{"BACK_HOMEPAGE" | translate}}</button>                
        </div>

    </div>
</div>