import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-no-camera-device',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './no-camera-device.component.html',
  styleUrl: './no-camera-device.component.scss'
})
export class NoCameraDeviceComponent {

}
