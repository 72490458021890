<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_HKID_INSTRUCTION.UPLOAD" | translate}}</h6>

    <div class="diff-bg-container">
        <a href="javascript:;" class="btn1 btn-primary1" (click)="open(hkid2018Modal)"><img alt="" class="w-100 img-fluid" src="assets/img/id_2018.png"></a>
    </div>
    <div class="diff-bg-container">
        <a href="javascript:;" class="btn1 btn-primary1" (click)="open(hkid2003Modal)"><img alt="" class="w-100 img-fluid" src="assets/img/id_2003.png"></a>
    </div>
    <div class="w-75 centered size-10px">
        {{"LOAN_HKID_INSTRUCTION.PURPOSE1" | translate}}<br>
        {{"LOAN_HKID_INSTRUCTION.PURPOSE2" | translate}}
    </div>

    <!-- 2018 Modal -->
    <ng-template #hkid2018Modal let-modal>
        <div class="modal-header header-with-cross">
            <div class="cross-click header-with-cross">
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="header-with-cross text-center">
                <h5 class="modal-title title-bold" id="modal-basic-title">{{"LOAN_HKID_INSTRUCTION.INSTRUCTION" | translate}}</h5>
            </div>            
        </div>

        <div class="modal-body">
            <div class="inline-div">
                <div class="w-70">
                    {{"LOAN_HKID_INSTRUCTION.GUIDE" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_0.png">
                </div>
            </div>
            <div class="inline-div">
                <div class="w-70">
                    <h6>{{"LOAN_HKID_INSTRUCTION.STEP1" | translate}}</h6>
                    {{"LOAN_HKID_INSTRUCTION.CONTENT1" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_1.png">
                </div>
            </div>
            <div class="inline-div">
                <div class="w-70">
                    <h6>{{"LOAN_HKID_INSTRUCTION.STEP2" | translate}}</h6>
                    {{"LOAN_HKID_INSTRUCTION.CONTENT2" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_2.png">
                </div>
            </div>
            <div class="inline-div">
                <div class="w-70">
                    <h6>{{"LOAN_HKID_INSTRUCTION.STEP3" | translate}}</h6>
                    {{"LOAN_HKID_INSTRUCTION.CONTENT3" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_3.png">
                </div>
           </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn-blue" (click)="startCapture(2018)">{{"LOAN_HKID_INSTRUCTION.CAPTURE" | translate}}</button>
        </div>
    </ng-template>

    <!-- 2003 Modal -->
    <ng-template #hkid2003Modal let-modal>
        <div class="modal-header header-with-cross">
            <div class="cross-click header-with-cross">
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="header-with-cross text-center">
                <h5 class="modal-title title-bold" id="modal-basic-title">{{"LOAN_HKID_INSTRUCTION.INSTRUCTION" | translate}}</h5>
            </div>            
        </div> 
        <div class="modal-body">
            <div class="inline-div">
                <div class="w-70">
                    {{"LOAN_HKID_INSTRUCTION.GUIDE" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_0.png">
                </div>
            </div>
            <div class="inline-div">
                <div class="w-70">
                    <h6>{{"LOAN_HKID_INSTRUCTION.STEP1" | translate}}</h6>
                    {{"LOAN_HKID_INSTRUCTION.CONTENT1" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_1.png">
                </div>
            </div>
            <div class="inline-div">
                <div class="w-70">
                    <h6>{{"LOAN_HKID_INSTRUCTION.STEP2" | translate}}</h6>
                    {{"LOAN_HKID_INSTRUCTION.CONTENT2" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_2.png">
                </div>
            </div>
            <div class="inline-div">
                <div class="w-70">
                    <h6>{{"LOAN_HKID_INSTRUCTION.STEP3" | translate}}</h6>
                    {{"LOAN_HKID_INSTRUCTION.CONTENT3" | translate}}
                </div>
                <div class="w-30">
                    <img alt="" class="w-100" src="assets/img/identification_step_3.png">
                </div>
           </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn-blue" (click)="startCapture(2003)">{{"LOAN_HKID_INSTRUCTION.CAPTURE" | translate}}</button>
        </div>
    </ng-template>

</div>