<div class="container">

    <div class="diff-bg-container text-center container-lg">
        <p class="with-margin">
            {{"BACK_SOON" | translate}}<br>
            {{"LOAN_MAINTAIN" | translate}}
        </p>
    </div>

</div>
