import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import {
  GoogleTagManagerConfiguration,
  GoogleTagManagerModule,
} from 'angular-google-tag-manager';
import { ConfigService } from './shared/services/config.service';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  LoanApplicationFeature,
  metaReducers,
} from './shared/store/loan-application.reducer';
import { mockInterceptor } from './shared/interceptors/mock.interceptor';
import { tokenInterceptor } from './shared/interceptors/token.interceptor';
import { loggingInterceptor } from './shared/interceptors/logging.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { PixelModule, PixelService } from '@bloomscorp/ngx-pixel';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const provideTranslation = () => ({
  defaultLanguage: 'zh-HK',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

function initializeAppFactory(
  httpClient: HttpClient,
  configService: ConfigService,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration,
  pixel: PixelService
): () => Observable<any> {
  return () =>
    httpClient.get( environment.domain + '/assets/config.json').pipe(
      tap((config) => {
        console.log('config: ', config);
        configService.setConfig(config);

        // GTM
        googleTagManagerConfiguration.set({
          id: configService.getConfig().gtm_code,
        });

        // Meta pixel
        pixel.remove();
        pixel.initialize(configService.getConfig().pixel_code);

      })
    );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([tokenInterceptor, loggingInterceptor])
      // withInterceptors([tokenInterceptor, loggingInterceptor, mockInterceptor])
    ),
    // this is text, provideHttpClient(withInterceptors([loggingInterceptor, mockInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [HttpClient, ConfigService, GoogleTagManagerConfiguration, PixelService],
    },
    importProvidersFrom([
      HttpClientModule,
      TranslateModule.forRoot(provideTranslation()),
      GoogleTagManagerModule.forRoot(),
      // for meta pixel, due to the limitation of the library, it is required to provide a pixel code in forRoot, therefore, a empty pixel id is input here, the real pixel id would be init at initializeAppFactory 
      PixelModule.forRoot({ enabled: true, pixelId: ' ' }), 
      
      NgbModule
    ]),
    provideStore(),
    provideState(LoanApplicationFeature.name, LoanApplicationFeature.reducer, {
      metaReducers: metaReducers,
    }),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
  ],
};
