

<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.PERSONAL_DETAIL"  | translate}}</h6>
    <div class="diff-bg-container">
        <form [formGroup]="form">

            <div class="form-group mb-2">
                <label for="residential" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.RESIDENTIAL_STATUS"  | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="residential" required>
                    <option value="" disabled selected hidden></option>
                    <option *ngFor="let el of residentialDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}
                    </option>
                </select>
                <div *ngIf="residentialFormControl.invalid && (residentialFormControl.dirty || residentialFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="residentialFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.REQUIRE_RSTATUS" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="marital" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.MARITAL_STATUS"  | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="marital" required>
                    <option value="" disabled selected hidden></option>
                    <option *ngFor="let el of maritalDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}
                    </option>
                </select>
                <div *ngIf="maritalFormControl.invalid && (maritalFormControl.dirty || maritalFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="maritalFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.REQUIRE_MARITAL" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="education" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.EDUCATION"  | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="education" required>
                    <option value="" disabled selected hidden></option>
                    <option *ngFor="let el of educationDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}
                    </option>
                </select>
                <div *ngIf="educationFormControl.invalid && (educationFormControl.dirty || educationFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="educationFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.REQUIRE_EDUCATION" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="martgage" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.MONTH_MORTGAGE"  | translate}}</label><br>
                <input type="number" class="form-control form-input-borders" formControlName="martgage" required>
                <div *ngIf="monthMartgageFormControl.invalid && (monthMartgageFormControl.dirty || monthMartgageFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="monthMartgageFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.REQUIRE_MORTGAGE" | translate}}
                    </div>
                    <div *ngIf="monthMartgageFormControl.errors?.['max']">{{"VALIDATION.MAX" | translate}}
                    </div>
                    <div *ngIf="monthMartgageFormControl.errors?.['min']">{{"VALIDATION.MIN" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="rental" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.MONTH_RENTAL"  | translate}}</label><br>
                <input type="number" class="form-control form-input-borders" formControlName="rental" required>
                <div *ngIf="monthRentalFormControl.invalid && (monthRentalFormControl.dirty || monthRentalFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="monthRentalFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.REQUIRE_RENTAL" | translate}}
                    </div>
                    <div *ngIf="monthRentalFormControl.errors?.['max']">{{"VALIDATION.MAX" | translate}}
                    </div>
                    <div *ngIf="monthRentalFormControl.errors?.['min']">{{"VALIDATION.MIN" | translate}}
                    </div>
                </div>
            </div>

        </form>
    </div>

    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!form.valid" (click)="continueClick()">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.CONTINUE"  | translate}}</button>
        <button type="" class="btn-white" (click)="backClick()">{{"LOAN_PERSONAL_DETAIL_DEMOGRAPHIC.BACK"  | translate}}</button>
    </div>
  
</div>