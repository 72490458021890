import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject, fromEvent, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-generic-error',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './generic-error.component.html',
  styleUrl: './generic-error.component.scss',
})
export class GenericErrorComponent implements OnInit, OnDestroy {
  applicationId$: Observable<number>;

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(private store: Store) {
    this.applicationId$ = this.store.pipe(
      select(LoanApplicationFeature.selectApplicationId)
    );
  }

  ngOnInit(): void {
    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  homeClick() {
    window.location.replace('/');
  }
}
