import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../shared/services/config.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import moment from 'moment';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-personal-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-personal-detail.component.html',
  styleUrl: './loan-personal-detail.component.scss',
})
export class LoanPersonalDetailComponent implements OnInit {
  form!: FormGroup;
  titleDataSource: any[] = [];

  constructor(
    private store: Store,
    private translate: TranslateService,
    private configService: ConfigService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit() {
    this.titleDataSource = this.configService.getConfig().title;

    this.initializeForm();
  }

  // Form
  get titleFormControl() {
    return this.form.get('title')!;
  }

  get familyNameFormControl() {
    return this.form.get('familyName')!;
  }

  get givenNameFormControl() {
    return this.form.get('givenName')!;
  }

  get dateOfBirthFormControl() {
    return this.form.get('dateOfBirth')!;
  }

  get hkidFormControl() {
    return this.form.get('hkid')!;
  }

  get chineseNameFormControl() {
    return this.form.get('chineseName')!;
  }

  get emailFormControl() {
    return this.form.get('email')!;
  }

  initializeForm() {
    this.store
      .select(LoanApplicationFeature.selectBasicInfo)
      .pipe(first())
      .subscribe({
        next: (basicInfo) => {
          console.log('prefill', basicInfo);
          this.form = new FormGroup({
            // title: new FormControl(this.titleDataSource[0].code, [
            title: new FormControl('', []),
            familyName: new FormControl(basicInfo.familyName, [
              Validators.required,
            ]),
            givenName: new FormControl(basicInfo.givenName, [
              Validators.required,
            ]),
            dateOfBirth: new FormControl(basicInfo.dateOfBirth, [
              Validators.required,
            ]),
            hkid: new FormControl(basicInfo.hkid, [Validators.required]),
            chineseName: new FormControl(basicInfo.chineseName, [
              // Validators.required,
            ]),
            email: new FormControl(basicInfo.email, [
              // Validators.required,
              Validators.email,
            ]),
          });
        },
        error: (e) => {
          console.info('error');
          console.log(e.message);
        },
        complete: () => {},
      });
  }

  continueClick(): void {
    // call api to get otp
    this.spinnerService.showSpinner();
    this.store
      .select(LoanApplicationFeature.selectApplicationId)
      .pipe(
        first(),
        switchMap((applicationId) => {
          let hkid = this.hkidFormControl.value;
          let applicantLastName = this.familyNameFormControl.value;
          let applicantFirstName = this.givenNameFormControl.value;
          let product = this.configService.getConfig().product_code;
          let applicationRequestDate = moment().format('DDMMYYYY');

          // update the basic info in store
          this.store.dispatch(
            LoanApplicationActions.setBasicInfo({
              title: this.titleFormControl.value,
              fullName: `${applicantLastName},${applicantFirstName}`,
              dateOfBirth: this.dateOfBirthFormControl.value,
              hkid: this.hkidFormControl.value,
              chineseName: this.chineseNameFormControl.value,
              email: this.emailFormControl.value,
            })
          );

          return this.apiService.velocityCheck(
            applicationId,
            hkid,
            applicantLastName,
            applicantFirstName,
            product,
            applicationRequestDate
          );
        })
      )
      .subscribe({
        next: (result) => {
          console.log('velocityCheck result: ', result);
          this.router.navigate(['/loan-selfie-instruction']);
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();
        },
      });
  }

  // Locale
  getCurrentLocale(): string {
    return this.translate.currentLang;
  }
}
