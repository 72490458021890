import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor() {}

  dataURLtoBlob(dataURL: string): Blob {
    // Extract the base64 data from the data URL
    const base64Data = dataURL.split(',')[1];

    // Extract the mime type
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    // Convert the base64 data to binary
    const binaryData = atob(base64Data);

    // Create a Uint8Array to hold the binary data
    const uint8Array = new Uint8Array(binaryData.length);

    // Populate the Uint8Array with the binary data
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const fileBlob = new Blob([uint8Array], { type: mimeString });
    return fileBlob;
  }

  getFileExtensionFromDataURL(dataURL: string):string {
    const extension = dataURL.split(',')[0].split(':')[1].split(';')[0].split('/')[1];;
    return extension
  }


}
