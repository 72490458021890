<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_OTP.ENTER" | translate}}</h6>
    <div class="diff-bg-container">
        <div class="text-center">
            <img alt="" class="img-fluid width-35" src="assets/img/icon_2_mobile.png">
        </div>

        <p class="with-margin text-center">{{"LOAN_OTP.SENT" | translate: { value: mobile} }}</p>

        <form [formGroup]="otpForm" class="mb-3">
            <div class="form-group">
                <ng-otp-input class="text-center" (onInputChange)="onOtpChange($event)" [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>
                <input type="text" class="form-control w-100 d-none" placeholder="otp" formControlName="otp" required>
                <div *ngIf="otpFormControl.invalid && (otpFormControl.dirty || otpFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="otpFormControl.errors?.['required']">{{"LOAN_OTP.OTP_REQUIRED" | translate}}
                    </div>
                </div>
            </div>
        </form>
        <div class="text-center">
            <div [hidden]="!isCountdowning" class="text-blue"><countdown #cd [config]="{leftTime: smsResendCountdown, format:'ss'}" (event)="handleCountDownEvent($event)"></countdown> s</div>
            <a [hidden]="isCountdowning" class="resend mb-5 text-center" href="javascript:;" (click)="resendClick()">{{"LOAN_OTP.RESEND" | translate}}</a>
        </div>

    </div>

    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!otpForm.valid" (click)="continueClick()">{{"LOAN_OTP.CONTINUE" | translate}}</button>
        <button class="btn-white" (click)="backClick()">{{"LOAN_OTP.BACK" | translate}}</button>
    </div>

</div>