<div class="container">
    
    <h6 class="text-center title-bold">{{"LOAN_PERSONAL_DETAIL.HEADER" | translate}}</h6>
    <div class="diff-bg-container">
        <p>{{"LOAN_PERSONAL_DETAIL.INSTRUCTION_MSG" | translate}}</p>

        <form [formGroup]="form">

            <div class="inline-div">
                <div class="form-group mb-2 input-title">
                    <label class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.TITLE" | translate}}</label><br>
                    <select class="form-select form-input-borders" formControlName="title">
                        <option value="" disabled selected hidden></option>
                        <option *ngFor="let el of titleDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}</option>
                    </select>
                    <div *ngIf="titleFormControl.invalid && (titleFormControl.dirty || titleFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="titleFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_TITLE" | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group mb-2 input-family-name">
                    <label for="familyName" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.FNAME" | translate}}</label><br>
                    <input type="text" class="form-control form-input-borders" formControlName="familyName" required>
                    <div *ngIf="familyNameFormControl.invalid && (familyNameFormControl.dirty || familyNameFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="familyNameFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_FNAME" | translate}}
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="form-group mb-2">
                <label for="givenName" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.GNAME" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="givenName" required>
                <div *ngIf="givenNameFormControl.invalid && (givenNameFormControl.dirty || givenNameFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="givenNameFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_GNAME" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="dateOfBirth" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.BIRTHDAY" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="dateOfBirth" placeholder="DD / MM / YYYY" required>
                <div *ngIf="dateOfBirthFormControl.invalid && (dateOfBirthFormControl.dirty || dateOfBirthFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="dateOfBirthFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_BIRTHDAY" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="hkid" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.HKID_NUM" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="hkid" required
                    [readonly]="true">
                <div *ngIf="hkidFormControl.invalid && (hkidFormControl.dirty || hkidFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="hkidFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_HKID" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="chineseName" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.CNAME" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="chineseName">
                <div *ngIf="chineseNameFormControl.invalid && (chineseNameFormControl.dirty || chineseNameFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="chineseNameFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_CNAME" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="email" class="text-gray">&emsp;{{"LOAN_PERSONAL_DETAIL.EMAIL" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="email">
                <div *ngIf="emailFormControl.invalid && (emailFormControl.dirty || emailFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="emailFormControl.errors?.['required']">{{"LOAN_PERSONAL_DETAIL.REQUIRE_EMAIL" | translate}}
                    </div>
                    <div *ngIf="emailFormControl.errors?.['email']">{{"LOAN_PERSONAL_DETAIL.INVALID_EMAIL" | translate}}
                    </div>
                </div>
            </div>

        </form>
    </div>

    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!form.valid"
            (click)="continueClick()">{{"LOAN_PERSONAL_DETAIL.CONTINUE" | translate}}</button>
    </div>

</div>