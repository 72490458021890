import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { Router, RouterLink } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SpinnerService } from '../../../shared/services/spinner.service';
import {
  ApiService,
  ApplicationNotFoundError,
  MaxVerifyOTPError,
} from '../../../shared/services/api.service';
import { Store } from '@ngrx/store';
import { first, map, switchMap } from 'rxjs';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { LoanApplicationActions } from '../../../shared/store/loan-application.actions';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-loan-enter-application-id',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-enter-application-id.component.html',
  styleUrl: './loan-enter-application-id.component.scss',
})
export class LoanEnterApplicationIdComponent {
  aidForm!: FormGroup;

  ngOnInit(): void {
    this.initializeForm();
    this.resumeFormData();
  }

  get aidFormControl() {
    return this.aidForm.get('aid')!;
  }

  get pnumFormControl() {
    return this.aidForm.get('pnum')!;
  }

  initializeForm() {
    this.aidForm = new FormGroup({
      aid: new FormControl('', [Validators.required]),
      pnum: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[4-9]\d{7}$/),
      ]),
    });
  }

  resumeFormData() {
  }

  continueClick(): void {
    let applicationId = this.aidFormControl.value;
    let mobile = this.pnumFormControl.value;

    this.spinnerService.showSpinner();
    this.apiService
      .getToken()
      .pipe(
        switchMap((token) => {
          // save token to store
          console.log('Token', token);
          this.store.dispatch(
            LoanApplicationActions.setToken({ token: token })
          );
          // call new application api
          return this.apiService.searchApplication(applicationId, mobile);
        })
      )
      .pipe(
        map((response) => {
          console.log('search application response: ');
          console.log(
            `response.ResponseInfo.TotalRecords: ${response.ResponseInfo.TotalRecords}`
          );
          console.log(response);

          return response;
        }),
        switchMap((response) => this.apiService.sendOTP(applicationId, mobile))
      )
      .subscribe({
        next: (response) => {
          console.log('sendOTP: ', response);
        },
        error: (e) => {
          console.info('error');
          this.spinnerService.hideSpinner();
          this.errorHandlingService.handleError(e);
        },
        complete: () => {
          console.info('complete');
          this.spinnerService.hideSpinner();

          this.store.dispatch(
            LoanApplicationActions.setApplicationId({
              applicationId: applicationId,
            })
          );

          this.store.dispatch(
            LoanApplicationActions.setMobile({
              mobile: mobile,
            })
          );

          this.router.navigate(['/loan-second-otp']);
        },
      });
  }

  backClick() {
    this.location.back();
  }

  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private location: Location,
    private errorHandlingService: ErrorHandlingService    
  ) {}
}
