import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../shared/services/config.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LoanApplicationFeature } from '../../../shared/store/loan-application.reducer';
import { first, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../shared/services/api.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loan-thank-you',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loan-thank-you.component.html',
  styleUrl: './loan-thank-you.component.scss',
})
export class LoanThankYouComponent implements OnInit, OnDestroy {
  applicationId: number | undefined;

  private startTime: number;
  private timer: any;
  // private timeoutThreshold = 15 * 60 * 1000; // 15min
  private timeoutThreshold = 3 * 1000; // 3s

  constructor(
    private store: Store,
    private router: Router,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private configService: ConfigService
  ) {
    this.startTime = Date.now();
  }

  ngOnInit() {
    this.store
      .select(LoanApplicationFeature.selectInfo)
      .pipe(first())
      .subscribe({
        next: ({ applicationId }) => {
          this.applicationId = applicationId;
        },
        error: (e) => {
          console.info('error');
          console.log(e.message);

          window.alert(e.message);
        },
        complete: () => {
          console.info('complete');
        },
      });

    // start timer for checking
    this.startTimer();
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    this.clearTimer();
  }

  private startTimer() {
    // perform checking every second
    this.timer = setInterval(() => {
      console.log('checking....');
      this.checkElapsedTime();
    }, 1000);
  }

  private clearTimer() {
    clearInterval(this.timer);
  }

  private getElapsedTime(): number {
    return Date.now() - this.startTime;
  }

  private checkElapsedTime(): void {
    const elapsedTime = this.getElapsedTime();
    console.log(`Elapsed time: ${elapsedTime} milliseconds`);

    if (elapsedTime >= this.timeoutThreshold) {
      window.location.replace('/');
    }
  }
}
