export interface WebSdkResult {
    code: string;
    step: number;
    nextStep: number;
    message: string;
    preview: string;
    image: string;
  }

  export enum WebSdkResultCode {
    OK = 'OK',
    LOOSE = 'LOOSE',
  }

  export enum WebSdkResultStep {
    STEP1 = 1,
    STEP2 = 2,
    STEP3 = 3,
    STEP4 = 4,
  }
  