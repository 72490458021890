import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { environment } from '../../../../environments/environment';
import { QrCodeModule } from 'ng-qrcode';

@Component({
  selector: 'app-non-mobile-device',
  standalone: true,
  imports: [CommonModule, RouterLink, SharedModule, QrCodeModule],
  templateUrl: './non-mobile-device.component.html',
  styleUrl: './non-mobile-device.component.scss',
})
export class NonMobileDeviceComponent {
  url = environment.domain

  constructor(
    private clipboard: Clipboard,
    private translateService: TranslateService,
    private router: Router
  ) {
    const currentLanguage = this.translateService.currentLang;
    console.log('currentLanguage: ', currentLanguage);
  }
  copyToClipboard() {
    const weblinkContent = document.getElementById('webLink')?.textContent;
    if (weblinkContent) {
      this.clipboard.copy(weblinkContent);
    }
  }
}
