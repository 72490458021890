<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_EMPLOYMENT_DETAIL.HEADER" | translate}}</h6>

    <div class="diff-bg-container">
        <form [formGroup]="form">

            <div class="form-group mb-2">
                <label for="employmentStatus" class="text-gray">&emsp;{{"LOAN_EMPLOYMENT_DETAIL.STATUS" | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="employmentStatus" required>
                    <option value="" disabled selected hidden></option>
                    <option *ngFor="let el of employmentStatusDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}
                    </option>
                </select>
                <div *ngIf="employmentStatusFormControl.invalid && (employmentStatusFormControl.dirty || employmentStatusFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="employmentStatusFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_ESTATUS" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="industry" class="text-gray">&emsp;{{"LOAN_EMPLOYMENT_DETAIL.INDUSTRY" | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="industry" required>
                    <option value="" disabled selected hidden></option>
                    <option *ngFor="let el of industryDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}
                    </option>
                </select>
                <div *ngIf="industryFormControl.invalid && (industryFormControl.dirty || industryFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="industryFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_INDUSTRY" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="companyName" class="text-gray">&emsp;{{"LOAN_EMPLOYMENT_DETAIL.COMPANY" | translate}}</label><br>
                <input type="text" class="form-control form-input-borders" formControlName="companyName">
                <div *ngIf="companyNameFormControl.invalid && (companyNameFormControl.dirty || companyNameFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="companyNameFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_COMPANY" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="position" class="text-gray">&emsp;{{"LOAN_EMPLOYMENT_DETAIL.POSITION" | translate}}</label><br>
                <select class="form-select form-input-borders" formControlName="position">
                    <option value="" disabled selected hidden></option>
                    <option *ngFor="let el of positionDataSource" [value]="el.code">{{ el[getCurrentLocale()] }}
                    </option>
                </select>
                <div *ngIf="positionFormControl.invalid && (positionFormControl.dirty || positionFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="positionFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_POSITION" | translate}}
                    </div>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="monthlyIncome" class="text-gray">&emsp;{{"LOAN_EMPLOYMENT_DETAIL.INCOME" | translate}}</label><br>
                <input type="number" class="form-control form-input-borders" formControlName="monthlyIncome"
                    required>
                <div *ngIf="monthlyIncomeFormControl.invalid && (monthlyIncomeFormControl.dirty || monthlyIncomeFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="monthlyIncomeFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_INCOME" | translate}}
                    </div>
                    <div *ngIf="monthlyIncomeFormControl.errors?.['max']">{{"VALIDATION.MAX" | translate}}
                    </div>
                    <div *ngIf="monthlyIncomeFormControl.errors?.['min']">{{"VALIDATION.MIN" | translate}}
                    </div>
                </div>
            </div>

            <div class="text-gray">&emsp;{{"LOAN_EMPLOYMENT_DETAIL.YEARS" | translate}}</div>
            <div class="inline-div mb-2">
                <div class="form-group input-year-month">
                    <select class="form-select form-input-borders" formControlName="workingYear" required>
                        <option value="" disabled selected hidden>YY</option>
                        <option *ngFor="let el of workingYearDataSource" [value]="el">{{ el }}
                        </option>
                    </select>
                    <div *ngIf="workingYearFormControl.invalid && (workingYearFormControl.dirty || workingYearFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="workingYearFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_YEAR" | translate}}
                        </div>
                    </div>
                </div>

                <div class="form-group input-year-month">
                    <select class="form-select form-input-borders" formControlName="workingMonth" required>
                        <option value="" disabled selected hidden>MM</option>
                        <option *ngFor="let el of workingMonthDataSource" [value]="el">{{ el }}
                        </option>
                    </select>
                    <div *ngIf="workingMonthFormControl.invalid && (workingMonthFormControl.dirty || workingMonthFormControl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="workingMonthFormControl.errors?.['required']">{{"LOAN_EMPLOYMENT_DETAIL.REQUIRE_MONTH" | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!form.valid"
            (click)="continueClick()">{{"LOAN_EMPLOYMENT_DETAIL.CONTINUE" | translate}}</button>
        <button type="" (click)="backClick()" class="btn-white">{{"LOAN_EMPLOYMENT_DETAIL.BACK" | translate}}</button>
    </div>

</div>