import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-loan-index',
  standalone: true,
  imports: [CommonModule, RouterLink,SharedModule],
  templateUrl: './loan-index.component.html',
  styleUrl: './loan-index.component.scss'
})
export class LoanIndexComponent {

  constructor(
    private clipboard: Clipboard,
    private translateService: TranslateService,
  ) { 
    const currentLanguage = this.translateService.currentLang;
    console.log('currentLanguage: ', currentLanguage);
  }
  copyToClipboard() {
    const weblinkContent = document.getElementById('webLink')?.textContent;
    if (weblinkContent) {
      this.clipboard.copy(weblinkContent);
    }
  }
}
