import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const checkMobileDeviceGuard: CanActivateFn = (route, state) => {
  function isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  if (!isMobileDevice()) {
    return inject(Router).parseUrl('error-non-mobile-device');
  }

  return true;
};
