<style>
  :-webkit-direct-focus {
    border: 0 !important;
    outline: none !important;
  }
</style>

<div id="caparea">

</div>

<div id="overlay-container">

  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <div id="img1div">
          <img alt="" id="img" class="w-100" src="assets/img/n1.png">
        </div>
      </div>
      <div class="col">
        <div id="img2div">
          <img alt="" id="img2" class="w-100" src="assets/img/n2.png">
        </div>
      </div>
      <div class="col">
        <div id="img3div">
          <img alt="" id="img3" class="w-100" src="assets/img/n3.png">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isProduction" style="position: absolute;top:110%;left:0;">
    img1: <input type="file" id="upload1" (change)="onFileSelected($event, 0)" /> <br>
    img2: <input type="file" id="upload2" (change)="onFileSelected($event, 1)" /> <br>
    img3: <input type="file" id="upload3" (change)="onFileSelected($event, 2)" /> <br>
    <a href="javascript:;" (click)="submit()">submit</a><br>
    <a href="javascript:;" (click)="skip()">Skip</a>
  </div>

  <div class="container">
    <p style="color:red; font-size: 20px;">{{ resultString$ | async }}</p>
  </div>




</div>

<!-- Retry Modal -->
<div class="model">
  <ng-template #retryModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Retry</h4>
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
    </div>
    <div class="modal-body">
      Image quality assessment fail, please upload again
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Ok click');back()">OK</button>
    </div>
  </ng-template>
</div>