import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationNotFoundError,
  DocAuthOcrError,
  GenericError,
  MaxResendOTPError,
  MaxVerifyOTPError,
  VelocityError,
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private router: Router) {}

  handleError(e: Error): void {
    console.log(e.message);

    switch (true) {
      case e instanceof MaxResendOTPError:
      case e instanceof MaxVerifyOTPError:
      case e instanceof ApplicationNotFoundError:
      case e instanceof GenericError:
      case e instanceof DocAuthOcrError:
        this.navigateToGenericErrorPage();
        break;

      case e instanceof VelocityError:
        this.navigateToVelocityErrorPage();
        break;
      default:
        window.alert(e.message);
        break;
    }
  }

  navigateToGenericErrorPage() {
    this.router.navigate(['/generic-error']);
  }

  navigateToVelocityErrorPage() {
    this.router.navigate(['/velocity-error']);
  }
}
