<div class="container">
    <h6 class="text-center title-bold">{{"LOAN_THANK_YOU.HEADER" | translate}}</h6>

    <div class="diff-bg-container text-center">
        <div class="text-center with-margin">
            <img alt="" class="img-fluid width-35" src="assets/img/icon_5_thanks.png">
        </div>
        <h5 class="with-margin title-bold">{{"LOAN_THANK_YOU.APPLICATION_ID" | translate}}</h5>
        <h5 class="with-margin title-bold">{{applicationId}}</h5>
        <hr class="hr-decoration">
        <p class="with-margin">{{"LOAN_THANK_YOU.INSTRUCTION" | translate}}</p>

        
        <div class="d-grid gap-2 mb-3">
            <button [routerLink]="['/loan-enter-application-id']" class="btn-lightblue" type="button" >{{"LOAN_THANK_YOU.UPLOAD_DOC" | translate}}</button>
        </div>

    </div>

</div>