<div class="container">

    <h6 class="text-center title-bold">{{"LOAN_ENTER_APPLICATION_ID.HEADER" | translate}}</h6>
    <div class="diff-bg-container">

        <form [formGroup]="aidForm" class="mb-3 with-margin text-center">
            <div class="form-group">
                <input type="text" class="form-control text-center gray text-blue-bg" [placeholder]="'LOAN_ENTER_APPLICATION_ID.APP_ID' | translate" formControlName="aid" required>
                <div *ngIf="aidFormControl.invalid && (aidFormControl.dirty || aidFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="aidFormControl.errors?.['required']">{{"LOAN_ENTER_APPLICATION_ID.REQUIRE_AID" | translate}}
                    </div>
                </div>
            </div>
            <hr class="hr-decoration with-margin">
            <p class="" href="">
                {{"LOAN_ENTER_APPLICATION_ID.ENTER_NUMBER" | translate}}
            </p> 
            <div class="form-group">
                <input type="tel" class="form-control text-center gray text-blue-bg" [placeholder]="'LOAN_ENTER_APPLICATION_ID.MOBILE' | translate" formControlName="pnum" required>
                <div *ngIf="pnumFormControl.invalid && (pnumFormControl.dirty || pnumFormControl.touched)"
                    class="alert alert-danger">
                    <div *ngIf="pnumFormControl.errors?.['required'] || pnumFormControl.errors?.['pattern']">{{"LOAN_ENTER_APPLICATION_ID.REQUIRE_PNUM" | translate}}
                    </div>
                </div>
            </div>
            <p class="with-margin">{{"LOAN_ENTER_APPLICATION_ID.INSTRUCTION" | translate}}<span class="size-10px">{{"LOAN_ENTER_APPLICATION_ID.REQUIRED" | translate}}</span>:</p>
            <div class="text-blue">
                {{"LOAN_ENTER_APPLICATION_ID.MATERIAL1" | translate}}<br>
                {{"LOAN_ENTER_APPLICATION_ID.MATERIAL2" | translate}}
            </div>
        </form>


    </div>
    

    <div class="d-grid gap-2">
        <button type="submit" class="btn-blue" [disabled]="!aidForm.valid" (click)="continueClick()">{{"LOAN_ENTER_APPLICATION_ID.CONTINUE" | translate}}</button>
        <button class="btn-white" (click)="backClick()">{{"LOAN_ENTER_APPLICATION_ID.BACK" | translate}}</button>
    </div>

</div>