import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  getFileExtensionFromType(file: File): string {
    const fileType = file.type;
    const extension = fileType.slice(fileType.lastIndexOf('/') + 1);
    return extension.toLowerCase();
  }
}
