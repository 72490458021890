<div class="container" *ngIf="!selectedFile">
    <h6 class="text-center title-bold">{{"LOAN_DOCUMENT_UPLOAD.PLEASE_UPLOAD" | translate}}<span [ngSwitch]="documentType">
            <span *ngSwitchCase="'1stMonthIncomeStatement'">{{"LOAN_DOCUMENT_UPLOAD.MIS1" | translate}}</span>
            <span *ngSwitchCase="'2ndMonthIncomeStatement'">{{"LOAN_DOCUMENT_UPLOAD.MIS2" | translate}}</span>
            <span *ngSwitchCase="'3rdMonthincomeStatement'">{{"LOAN_DOCUMENT_UPLOAD.MIS3" | translate}}</span>
            <span *ngSwitchCase="'AddressProof'">{{"LOAN_DOCUMENT_UPLOAD.ADDRESS_PROOF" | translate}}</span>
            <span *ngSwitchCase="'BankAccountHolderProof'">{{"LOAN_DOCUMENT_UPLOAD.HOLDER_PROOF" | translate}}</span>
            <span *ngSwitchDefault>{{"LOAN_DOCUMENT_UPLOAD.OTHER" | translate}}</span>
        </span>
    </h6>

    <div class="diff-bg-container">
        <div class="text-center">
            <img alt="" class="width-35" src="assets/img/icon_4_file.png">
        </div>
        <div class="mb-5 text-center mt-3 w-75 centered">
            {{"LOAN_DOCUMENT_UPLOAD.UPLOAD_INSTRUCTION" | translate}}<br>
            {{"LOAN_DOCUMENT_UPLOAD.ACCEPT_FORMAT" | translate}}
        </div>
        <div class="d-grid gap-2 mb-3">
            <button class="btn-blue" type="button" (click)="open(guidelineModal)">{{"LOAN_DOCUMENT_UPLOAD.UPLOAD" | translate}}</button>
        </div>
    </div>

</div>

<div class="container" *ngIf="selectedFile">
    <h6 class="text-center title-bold">{{"LOAN_DOCUMENT_UPLOAD.CONFIRM_HEADER" | translate}}<span [ngSwitch]="documentType">
            <span *ngSwitchCase="'1stMonthIncomeStatement'">{{"LOAN_DOCUMENT_UPLOAD.MIS1" | translate}}</span>
            <span *ngSwitchCase="'2ndMonthIncomeStatement'">{{"LOAN_DOCUMENT_UPLOAD.MIS2" | translate}}</span>
            <span *ngSwitchCase="'3rdMonthincomeStatement'">{{"LOAN_DOCUMENT_UPLOAD.MIS3" | translate}}</span>
            <span *ngSwitchCase="'AddressProof'">{{"LOAN_DOCUMENT_UPLOAD.ADDRESS_PROOF" | translate}}</span>
            <span *ngSwitchCase="'BankAccountHolderProof'">{{"LOAN_DOCUMENT_UPLOAD.HOLDER_PROOF" | translate}}</span>
            <span *ngSwitchDefault>{{"LOAN_DOCUMENT_UPLOAD.OTHER" | translate}}</span>
        </span>
    </h6>

    <div class="mb-3 text-center w-75 centered">
        <img alt="" *ngIf="isImageSelected" [src]="selectedFileString" class="img-fluid w-100 preview" alt="Preview" />
        <div *ngIf="!isImageSelected"><h2 class="pdf-placeholder w-100">PDF file</h2></div>
    </div>

    <div class="d-grid gap-2">
        <!-- <p class="btn-blue text-center">{{"LOAN_DOCUMENT_UPLOAD.DOCUMENT_CAT" | translate}}</p> -->
        <!-- <input *ngIf="documentType === 'OtherDocument'" class="form-control form-input-borders text-center btn-size" type="text" [(ngModel)]="remark" placeholder="Supporting Document"> -->
        <input *ngIf="false" class="form-control form-input-borders text-center btn-size" type="text" [(ngModel)]="remark" placeholder="Supporting Document">
        <label for="upload-again" class="btn-blue text-center">{{"LOAN_DOCUMENT_UPLOAD.UPLOAD_AGAIN" | translate}}</label>
        <input type="file" id="upload-again" (change)="onFileSelected($event)" style="display:none;" />
        
        <button class="btn-blue" type="button" (click)="continue()">{{"LOAN_DOCUMENT_UPLOAD.CONTINUE" | translate}}</button>
    </div>

</div>

<!-- Guideline Modal -->
<ng-template #guidelineModal let-modal>
    <div class="modal-header header-with-cross">
        <div class="cross-click header-with-cross">
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="header-with-cross text-center">
            <h4 class="modal-title title-bold" id="modal-basic-title">{{"LOAN_DOCUMENT_UPLOAD.INSTRUCTION" | translate}}</h4>
        </div>            
    </div> 
    <div class="modal-body text-left" [innerHTML]="'LOAN_DOCUMENT_UPLOAD.INSTRUCTION_CONTENT' | translate">
    </div>
    <div class="modal-footer" class="text-center">
        <label for="upload" class="btn-blue">{{"LOAN_DOCUMENT_UPLOAD.CONTINUE" | translate}}</label>
        <input type="file" id="upload" (change)="onFileSelected($event)" style="display:none;" /><br><br>
    </div>
</ng-template>