<div class="size-10px">
  <footer class="d-flex flex-wrap justify-content-between align-items-center my-4 border-top">
    <div class="col-md-12 warning-message text-center">

      {{"WARNING1" | translate}}<br>
      {{"WARNING2" | translate}}<br>

    </div>

    <div *ngIf="router.url == '/'" class="col-md-12 container text-center text-gray">
      {{"INTERESTMSG" | translate}}
    </div>

    <!-- <div *ngIf="router.url != '/'" class="col-md-12 container text-center text-gray">
      {{"FOOTER1" | translate}} <br>
      {{"FOOTER2" | translate}} <br>
      {{"FOOTER3" | translate}} <br>
      {{"FOOTER4" | translate}} <br>
      {{"FOOTER5" | translate}} <br>
    </div> -->

    <div class="col-md-12 container text-center text-gray">
      <!-- {{"FOOTER1" | translate}} <br> -->
      {{"FOOTER2" | translate}} <br>
      {{"FOOTER3" | translate}} <br>
      {{"FOOTER4" | translate}} <br>
      {{"FOOTER5" | translate}} <br>
    </div>

    <div *ngIf="!isProduction" class="col-md-12 container text-center text-gray">
      App version: {{version}}
    </div>

    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li class="ms-3"><a class="text-body-secondary" href="#"><svg class="bi" width="24" height="24">
            <use xlink:href="#twitter"></use>
          </svg></a></li>
      <li class="ms-3"><a class="text-body-secondary" href="#"><svg class="bi" width="24" height="24">
            <use xlink:href="#instagram"></use>
          </svg></a></li>
      <li class="ms-3"><a class="text-body-secondary" href="#"><svg class="bi" width="24" height="24">
            <use xlink:href="#facebook"></use>
          </svg></a></li>
    </ul>
  </footer>
</div>