import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_VERSION } from '../../../../version';
import { SharedModule } from '../../../shared/shared.module';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
 version : string = APP_VERSION;
 isProduction: boolean = environment.production;

 constructor(
  public router: Router
  ){
  }
}
